import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import TopBarPage from "../ui-components/top-bar-page";
import { getItems } from "../functions/request";
import { useAuth } from '../context/auth.context';
import TicketResponse from "../components/ticket-response";

const TicketsOther = (props) => {
  const [data, setData] = useState(null);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(12);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [assignedId, setAssignedId] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [ticket, setTicket] = useState(null);
  const [showResponse, setShowResponse] = useState(false);

  const { role, token, user } = useAuth();


  useEffect(() => {
    document.title = "Tickets - CIPS";
    if (!user.canViewTickets) window.location.href = "/";
  }, [user]);

  const callData = async () => {
    setData(null);
    let data = await getItems('/tickets/list/other', token, pageSize, page, {
      q: query,
      filter,
      assigned: assignedId,
      year,
    });
    setData(data.results.items);
    setLength(data.results.length);
  }

  useEffect(() => {
    callData();
  }, [pageSize, page, query, filter, assignedId, year])

  const nextPage = async () => {
    const newPage = page + pageSize;
    setPage(newPage);
  }

  const lastPage = async () => {
    const prevPage = page - pageSize;
    setPage(prevPage);
  }

  const searchQuery = async (e, _query) => {
    e.preventDefault();
    setQuery(_query);
    setPage(0);
  }

  const handleChangeFilter = async (value) => {
    setFilter(value);
  }

  const handleChangeAssigned = async (value) => {
    setAssignedId(value);
  }

  const handleChangeYear = async (value) => {
    setYear(value);
  }

  const handleChooseTicket = (item) => {
    setTicket(item);
    setShowResponse(true);
  }

  return (
    <div>
      <TopBarPage
        page="Altri Tickets"
        showFilterAndOthers={false}
        setYear={handleChangeYear}
        placeholderInput="Cerca per Numero, Soggetto, Stadio, Totale"
        searchQuery={searchQuery}
        setFilterData={handleChangeFilter}
        role={role}
        type="Quo"
        defaultId={null}
        handleChangeAssigned={handleChangeAssigned}
      />

      <hr />

      {data != null ? (
        <div className="content-page">
          <p>
            Visualizzando da {length < 1 ? 0 : page + 1} a{" "}
            {page + pageSize <= length ? page + pageSize : length} di {length}
          </p>
          <div
            className="content-table overflow-auto"
            style={{ maxHeight: "calc(100% - 81px)" }}
          >
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Nr. Ticket</th>
                  <th>Titolo</th>
                  <th>Stato</th>
                  <th>Richiedente</th>
                  <th>Data Apertura</th>
                  <th>Ultimo Aggiornamento</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.ticket_no}</td>
                      <td>{item.ticket_title}</td>
                      <td>{item.ticketstatus}</td>
                      <td>
                        {item.related ? item.related.accountname : 'n/a' }
                      </td>
                      <td>{item.createdtime}</td>
                      <td>{item.modifiedtime}</td>
                      <td>
                        <a className="badge badge-primary mr-1" href={`/tickets/${item.id}`}>Dettagli</a>
                        { item.ticketstatus !== 'Closed' ?  <a className="badge badge-primary" href="#" onClick={() => handleChooseTicket(item)}>Rispondi</a> : null }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Pagination className="mr-auto">
              {page >= pageSize ? (
                <PaginationItem onClick={lastPage}>
                  <PaginationLink>Indietro</PaginationLink>
                </PaginationItem>
              ) : null}
              {length > 12 ? (
                <PaginationItem onClick={nextPage}>
                  <PaginationLink next>Avanti</PaginationLink>
                </PaginationItem>
              ) : null}
            </Pagination>
          </div>
        </div>
      ) : (
        <div className="loader-square"></div>
      )}

      { showResponse ? <TicketResponse setShow={setShowResponse} ticket={ticket} /> : null }

    </div>
  );
};

export default TicketsOther;
