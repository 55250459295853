import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { httpPost, request } from "../../functions/request";
import "./mail-box-help.scss";
import { useAuth } from "../../context/auth.context";

const TicketResponse = ({ setShow, ticket }) => {
  const { token } = useAuth();

  const [message, setMessage] = useState("");
  const [btnClass, setBtnClass] = useState("btn btn-secondary");
  const [btnText, setBtnText] = useState("Invia messaggio");

  const sendMail = async e => {
    e.preventDefault();

    setBtnClass("btn btn-secondary disabled");
    setBtnText("Invio del messaggio in corso...");

    const data = await httpPost(`/tickets/response/${ticket.id}`, { response: message }, token)

    if (!data.ok) {
      setBtnClass("btn btn-danger disabled");
      setBtnText(data.results);

      setTimeout(() => {
        setBtnClass("btn btn-secondary");
        setBtnText("Invia messaggio");
      }, 1000);

      return;
    }

    setBtnClass("btn btn-success disabled");
    setBtnText(data.results);

    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <div className="mail-box-help-wrapper">
      <div className="mail-box-help">
        <div className="mail-box-top">
          <h2>Rispondi al ticket</h2>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setShow(false);
            }}
          >
            &times;
          </a>
        </div>

        <hr />

        <form onSubmit={sendMail}>
          <label htmlFor="msg">Messaggio</label>
          <textarea
            className="form-control"
            name="msg"
            id="msg"
            defaultValue={message}
            onChange={e => setMessage(e.target.value)}
          ></textarea>

          <hr />

          <input
            type="submit"
            disabled={btnClass.includes("disabled")}
            className={btnClass}
            value={btnText}
          />
        </form>
      </div>
    </div>
  );
};

export default TicketResponse;
