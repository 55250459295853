import icoDashboard from '../media/dashboard.svg';
import icoOp from '../media/opportunity.svg';
import icoPreventici from '../media/preventivi.svg';
import icoCart from '../media/cart.svg';
import icoFatture from '../media/fatture.svg';
import icoCalendar from '../media/calendar.svg';
import icoLeads from '../media/leads.svg';
import icoPublicDoc from '../media/public-document.svg';
import icoPrivateDoc from '../media/private-document.svg';
import icoProfile from '../media/profile.svg';
import icoSearch from '../media/search.svg';
import icoLogout from '../media/logout.svg';
import icoCips from '../media/logo-cips.png';
import icoSupport from '../media/support-icon.svg';
import icoRenew from '../media/renew.svg';
import icoHelp from '../media/help.svg';
import icoExport from '../media/export.svg';
import icoConfiguratore from '../media/settings.png';
import icoAdd from '../media/add.png';

export default {
  icoDashboard,
  icoOp,
  icoPreventici,
  icoCart,
  icoFatture,
  icoCalendar,
  icoLeads,
  icoPublicDoc,
  icoPrivateDoc,
  icoSearch,
  icoProfile,
  icoLogout,
  icoCips,
  icoSupport,
  icoRenew,
  icoHelp,
  icoExport,
  icoConfiguratore,
  icoAdd
}
