import React, { useState } from "react";
import axios from "axios";
import { Col, Row, Container, Alert, Input } from "reactstrap";
import Icon from "../../ui-components/icons";
import { useAuth } from "../../context/auth.context";
import { httpPost } from "../../functions/request";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loginText, setLoginText] = useState("Login");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const [usernameForgotPassword, setUsernameForgotPassword] = useState("");
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // init the message error banner
    setError(false);
    setMessage("");
    setLoginText("Accesso in corso...");

    const status = await login(username, password);

    if (!status) {
      setError(true);
      setMessage("Credenziali non valide");
      setLoginText("Login");
      return;
    }

    return (window.location.href = "/");
  };

  const handleSendPasswordRequest = async (e) => {
    e.preventDefault();

    if (emailForgotPassword == "") return alert("Campo email obbligatorio.");

    const result = await httpPost(
      "/auth/security/forgot",
      { email: emailForgotPassword },
      null
    );

    if (!result.ok) {
      setError(true);
      setMessage(result.result);
      setSuccess(false);
    } else {
      setError(false);
      setMessage(result.results);
      setSuccess(true);
    }

    setShowForgotPassword(false);
  };

  return (
    <Container fluid={true} className="home-page-user-auth">
      <Row>
        <Col sm="6" className="cips-logo">
          <div className="cips-logo-copy">
            <img
              src={Icon.icoCips}
              alt="Logo CIPS"
              style={{
                width: "100%",
                maxWidth: 350,
              }}
            />
            <p className="cips-logo-copy-title">Area Riservata</p>
            <p className="cips-logo-copy-text">
              Effettua l'accesso o registrati
            </p>
          </div>
        </Col>
        <Col sm="6" className="cips-login-form" style={{ background: "#fff" }}>
          <form onSubmit={handleSubmit} className="w-100">
            <p className="cips-title">Accedi</p>
            <p className="cips-question-auth">
              Non sei registrato? <a href="/registration">Registrati</a>
            </p>

            <label htmlFor="username">
              Username <span className="required">*</span>
            </label>
            <Input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              name="username"
              id="username"
            />

            <label htmlFor="password">
              Password <span className="required">*</span>
            </label>
            <Input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
            />

            <input
              type="submit"
              value={loginText}
              className="btn btn-primary btn-block"
            />

            <label htmlFor="remember_me" className="remember_me mt-3">
              <input
                onChange={(e) => setRememberMe(!rememberMe)}
                type="checkbox"
                name="remember_me"
                id="remember_me"
                defaultChecked={rememberMe}
              />{" "}
              Mantieni l’accesso su questo dispositivo
            </label>

            {error ? (
              <Alert color="danger" className="font-weight-bold mt-3">
                {message}
              </Alert>
            ) : (
              <></>
            )}

            {success ? (
              <Alert
                color="success"
                className="font-weight-bold"
                style={{ marginTop: 10 }}
              >
                {message}
              </Alert>
            ) : (
              <></>
            )}

            {showForgotPassword ? (
              <>
                <hr />
                <p className="font-weight-bold h3">Recupero password</p>
                <label htmlFor="email">
                  La tua Email <span className="required">*</span>
                </label>
                <Input
                  onChange={(e) => setEmailForgotPassword(e.target.value)}
                  type="email"
                  name="email"
                  id="email"
                  required={true}
                />

                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={handleSendPasswordRequest}
                >
                  Conferma
                </button>
              </>
            ) : null}

            <p className="cips-question-auth mt-4">
              {!showForgotPassword ? (
                <>
                  Password dimenticata?{" "}
                  <a
                    href="#"
                    onClick={() => setShowForgotPassword(!showForgotPassword)}
                  >
                    Recupera
                  </a>
                </>
              ) : (
                <a
                  href="#"
                  onClick={() => setShowForgotPassword(!showForgotPassword)}
                >
                  Chiudi
                </a>
              )}
            </p>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
