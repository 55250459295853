import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';

import TopBarPage from '../ui-components/top-bar-page'
import { httpGet, request } from '../functions/request';
import Icon from '../ui-components/icons';
import { useAuth } from "../context/auth.context";

const Status = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [assignedId, setAssignedId] = useState(null);

    const [statusOpp, setStatusOpp] = useState(null);
    const [statusAss, setStatusAss] = useState(null);
    const [statusOrd, setStatusOrd] = useState(null);
    const [statusInv, setStatusInv] = useState(null);
    const [statusQuo, setStatusQuo] = useState(null);

    const { role, token } = useAuth();

    useEffect(() => {
        document.title = 'Stato - CIPS';
    }, []);

    useEffect(() => {
        const getStatusOpp = async () => {
            const data = await httpGet('/opportunities/status',{ assigned: assignedId, year: year }, token);
            setStatusOpp(data.results);
        }

        const getStatusAss = async () => {
            const data = await httpGet('/assets/status',{ assigned: assignedId, year: year }, token);
            setStatusAss(data.results);
        }

        const getStatusOrd = async () => {
            const data = await httpGet('/orders/status',{ assigned: assignedId, year: year }, token);
            setStatusOrd(data.results);
        }

        const getStatusInv = async () => {

        }

        const getStatusQuo = async () => {
            const data = await httpGet('/quotes/status',{ assigned: assignedId, year: year }, token);
            setStatusQuo(data.results);
        }

        setStatusOpp(getStatusOpp());
        setStatusAss(getStatusAss());
        setStatusOrd(getStatusOrd());
        setStatusInv(getStatusInv());
        setStatusQuo(getStatusQuo());
    }, [assignedId, year]);

    return (
        <div className="status">
            <TopBarPage page="Stato" role={role} setYear={setYear} showYear={true} searchBtn={false} handleChangeAssigned={setAssignedId} />

            <hr />

            <Row>
                {
                    statusOpp != null && role != 'CLIENT' ?
                        <Col md="4">
                            <div className="preview-item">
                                <div className="preview-top">
                                    <img src={Icon.icoOp} alt="Opportunità" />
                                    <span>Opportunità</span>
                                    <a href="/opportunities" className="btn btn-secondary">Lista completa</a>
                                </div>
                                <hr />
                                <table className="table-status w-100">
                                    <tbody>
                                        <tr>
                                            <td>Tutti</td>
                                            <th style={{ textAlign: 'right' }}>{statusOpp.tutti}</th>
                                        </tr>
                                        <tr>
                                            <td>Aperte</td>
                                            <th style={{ textAlign: 'right' }}>{statusOpp.aperte}</th>
                                        </tr>
                                        <tr>
                                            <td>Vinte</td>
                                            <th style={{ textAlign: 'right' }}>{statusOpp.vinte}</th>
                                        </tr>
                                        <tr>
                                            <td>Perse</td>
                                            <th style={{ textAlign: 'right' }}>{statusOpp.perse}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        : null}

                {
                    statusOrd != null ?
                        <Col md="4">
                            <div className="preview-item">
                                <div className="preview-top">
                                    <img src={Icon.icoCart} alt="Ordini" />
                                    <span>Ordini</span>
                                    <a href="/ordini" className="btn btn-secondary">Lista completa</a>
                                </div>
                                <hr />
                                <table className="table-status w-100">
                                    <tbody>
                                        <tr>
                                            <td>Tutti</td>
                                            <th style={{ textAlign: 'right' }}>{statusOrd.tutti}</th>
                                        </tr>
                                        <tr>
                                            <td>Approvati</td>
                                            <th style={{ textAlign: 'right' }}>{statusOrd.approvati}</th>
                                        </tr>
                                        <tr>
                                            <td>Sospesi</td>
                                            <th style={{ textAlign: 'right' }}>{statusOrd.sospesi}</th>
                                        </tr>
                                        <tr>
                                            <td>Cancellati</td>
                                            <th style={{ textAlign: 'right' }}>{statusOrd.cancellati}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        : null}

                {
                    statusAss != null ?
                        <Col md="4">
                            <div className="preview-item">
                                <div className="preview-top">
                                    <img src={Icon.icoCalendar} alt="Assets" />
                                    <span>Assets e Scadenze</span>
                                    <a href="/asset-e-scadenze" className="btn btn-secondary">Lista completa</a>
                                </div>
                                <hr />
                                <table className="table-status w-100">
                                    <tbody>
                                        <tr>
                                            <td>Tutti</td>
                                            <th style={{ textAlign: 'right' }}>{statusAss.tutti}</th>
                                        </tr>
                                        <tr>
                                            <td>In Scadenza</td>
                                            <th style={{ textAlign: 'right' }}>{statusAss.inScadenza}</th>
                                        </tr>
                                        <tr>
                                            <td>Scaduti</td>
                                            <th style={{ textAlign: 'right' }}>{statusAss.scadute}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        : null}

                {
                    statusQuo != null ?
                        <Col md="4" className="mt-2">
                            <div className="preview-item">
                                <div className="preview-top">
                                    <img src={Icon.icoPreventici} alt="Preventivi" />
                                    <span>Preventivi</span>
                                    <a href="/preventivi" className="btn btn-secondary">Lista completa</a>
                                </div>
                                <hr />
                                <table className="table-status w-100">
                                    <tbody>
                                        <tr>
                                            <td>Tutti</td>
                                            <th style={{ textAlign: 'right' }}>{statusQuo.tutti}</th>
                                        </tr>
                                        <tr>
                                            <td>Aperti</td>
                                            <th style={{ textAlign: 'right' }}>{statusQuo.aperti}</th>
                                        </tr>
                                        <tr>
                                            <td>Accettati</td>
                                            <th style={{ textAlign: 'right' }}>{statusQuo.accettati}</th>
                                        </tr>
                                        <tr>
                                            <td>Non Accettati</td>
                                            <th style={{ textAlign: 'right' }}>{statusQuo.nonAccettati}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        : null}
            </Row>
        </div>
    );
}

export default Status;
