import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { request, getItems, getItemByQuery, httpPost } from '../../functions/request';
import './renewal-request.scss';
import { useAuth } from '../../context/auth.context';

const RenewalSuite = ({ assets, setAssetSuite, setMessageSuite, btnClass, btnText, defaultProduct = null, data = null }) => {
  const [ser, setSer] = useState(null);
  const [lic, setLic] = useState(null);
  const [dat, setDat] = useState(null);

  useState(() => {
    console.log(data);
    console.log(defaultProduct);

    if (data == null) return ;

    setSer(data.serialnumber);
    setLic(data.cf_1051);
    setDat(data.data_scadenza);

  }, [defaultProduct, data]);

  const showDetails = async (e) => {
    setAssetSuite(e);

    const data = assets.filter(i => i.productname === String(e).split(':')[1]);

    setSer(data[0].seriale);
    setLic(data[0].licenza);
    setDat(data[0].data_scadenza);
  }

  return (
    <>
      <hr />

      <label htmlFor="assets">Seleziona un prodotto</label>
      <select className="form-control" id="assets" onChange={(e) => showDetails(e.target.value)} defaultValue={defaultProduct}>
        <option value="">Seleziona un prodotto</option>
        {assets.map((item, index) => <option key={index} value={`${item.asset_no}:${item.productname}`}>{`${item.data_scadenza} - ${item.productname}`}</option>)}
      </select>

      {
        ser !== null && lic !== null && dat !== null ?
          <>
            <hr />
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Nr. Seriale:</strong> {ser}</p>
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Nr. Licenza:</strong> {lic}</p>
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Data Scadenza:</strong> {dat}</p>
            <hr />
          </>
          : null
      }

      <label htmlFor="message">Messaggio</label>
      <textarea name="message" id="message" className="form-control" rows="10" onChange={(e) => setMessageSuite(e.target.value)}></textarea>

      <hr />

      <input  type="submit" className={`${btnClass} mt-2`} value={btnText} />
    </>
  )
};

const RenewalOffer = ({ assets, setAssetOffer, setMessageOffer, btnClass, btnText }) => {
  const [assetsSelected, setAssetsSelected] = useState([]);
  const [assetSelected, setAssetSelected] = useState(null);

  const handleSelectAsset = async () => {
    if (assetSelected == null) return;

    setAssetsSelected([...assetsSelected, assetSelected]);
    setAssetOffer([...assetsSelected, assetSelected])
  }

  const handleRemove = async (val) => {
    setAssetsSelected(assetsSelected.filter(item => item !== val));
    setAssetOffer(assetsSelected.filter(item => item !== val))
  }

  return (
    <>
      <hr />

      <Row>
        <Col md="9">
          <label htmlFor="assets">Seleziona un prodotto</label>
          <select className="form-control" id="assets" onChange={(e) => setAssetSelected(e.target.value)}>
            <option value="">Seleziona un prodotto</option>
            {assets.map((item, index) => <option key={index} value={`${item.asset_no}:${item.assetname}`}>{item.asset_no} - {item.assetname}</option>)}
          </select>
        </Col>
        <Col md="3">
          <label>&nbsp;</label>
          <input type="button" className="btn btn-secondary btn-block" value="Aggiungi" onClick={handleSelectAsset} />
        </Col>
      </Row>

      {assetsSelected.length > 0 ?
        <>
          <label htmlFor="">Licenze selezionate:</label>
          <div className="selected-license">
            {assetsSelected.map((value, index) => {
              return (
                <div key={index} className="item-selected">{value.split(':')[0]} <a href="#" onClick={(e) => { e.preventDefault(); handleRemove(value) }}>&times;</a></div>
              )
            })}
          </div>

          <hr />
        </> : null}

      <label htmlFor="message">Messaggio</label>
      <textarea name="message" id="message" className="form-control" rows="10" onChange={(e) => setMessageOffer(e.target.value)}></textarea>

      <hr />

      <input type="submit" className={btnClass} value={btnText} />
    </>
  )
};

const RenewalRequest = ({ setShowRenewalReq, defaultAssets = null, defaultTypeRequest = '' }) => {
  const [type, setType] = useState(defaultTypeRequest);
  const [assets, setAssets] = useState(null);
  const [assetsFilteredToSend, setAssetsFilteredToSend] = useState(null);
  const [clients, setClients] = useState(null);
  const [assetSuite, setAssetSuite] = useState(defaultAssets);
  const [messageSuite, setMessageSuite] = useState('');
  const [assetOffer, setAssetOffer] = useState([]);
  const [messageOffer, setMessageOffer] = useState('');
  const [client, setClient] = useState(null);
  const [defaultProduct, setDefaultProduct] = useState(null);
  const [nameDefaultProduct, setNameDefaultProduct] = useState(null);
  const [btnClass, setBtnClass] = useState('btn btn-secondary mt-2');
  const [btnText, setBtnText] = useState('Invia Richiesta');

  const { token } = useAuth();

  useEffect(() => {
    const getAssets = async () => {
      const data = await getItems('/assets/list/request-data', token, null, null, {});
      setAssets(data.results.items);
      setClients(data.results.clients);
    }

    getAssets();
  }, [])

  // get default assets if it exist
  useEffect(() => {
    if (defaultAssets == null) return ;

    const assetNo = String(defaultAssets).split(':')[0];
    const getAssetByNo = async (no) => {
      const data = await getItems(`/assets/single/${no}`, token, null, null, {});
      setDefaultProduct(data.results.asset);
      setNameDefaultProduct(data.results.product.productname);
      setClient(data.results.asset.cf_1053)
    }

    getAssetByNo(assetNo);
  }, [defaultAssets]);

  useEffect(() => {
    if (assets !== null) {
      let assetsFiltered = [];

      if (client !== '') {
        for (let i = 0; i < assets.length; i++) {
          if (assets[i].client === client) {
            assetsFiltered.push(assets[i]);
          }
        }
        return setAssetsFilteredToSend(assetsFiltered);
      }

      return setAssetsFilteredToSend(assets);
    }
  }, [client]);

  const sendMail = async (e) => {
    e.preventDefault();

    setBtnText('Invio del messaggio in corso...');

    let typereq = type === 'Rinnovo Suite' ? 'rinnovo' : (type === 'Richiesta Offerta Generica' ? 'offer' : null);
    let senddata = {}

    if (typereq === 'rinnovo') {
      senddata = {
        object: '',
        type: typereq,
        asset: assetSuite,
        client: client,
        message: messageSuite
      }
    }

    if (typereq === 'offer') {
      senddata = {
        object: '',
        type: typereq,
        asset: assetOffer,
        client: client,
        message: messageOffer
      }
    }

    const data = await httpPost("/users/assistence/renewal", { ...senddata }, token);

    if (!data.ok) {
      setBtnClass('btn btn-danger mt-2');
      setBtnText(data.result);
    } else {
      setBtnClass('btn btn-success mt-2');
      setBtnText(data.results);
      return setShowRenewalReq(false);
    }

  }

  return (
    <div className="renewal-request-wrapper">
      <div className="renewal-request">
        <div className="renewal-request-top">
          <h2>Richiesta di Rinnovo</h2>
          <a href="#" onClick={(e) => { e.preventDefault(); setShowRenewalReq(false); }}>&times;</a>
        </div>

        <hr />

        <form onSubmit={sendMail}>
          <label htmlFor="type">Seleziona il tipo di richiesta?</label>

          <div className="row">
            <div className="col-md-6">
              <select defaultValue={defaultTypeRequest||type} className="form-control" type="text" id="type" onChange={(e) => setType(e.target.value)}>
                <option value="">Seleziona un opzione</option>
                <option value="Rinnovo Suite">Rinnovo Suite</option>
                {/* <option value="Richiesta Offerta Generica">Richiesta Offerta Generica</option> */}
              </select>
            </div>
            <div className="col-md-6">
              <select className="form-control" id="client" value={client} onChange={(e) => setClient(e.target.value)}>
                <option value="">Seleziona un cliente</option>
                {
                  clients != null ?
                    clients.map((item, index) => <option key={index} value={`${item}`}>{`${item}`}</option>)
                    : null
                }
              </select>
            </div>
          </div>

          {assetsFilteredToSend != null && type == 'Rinnovo Suite' ? <RenewalSuite defaultAssets={defaultAssets} data={defaultProduct} defaultProduct={nameDefaultProduct} assets={assetsFilteredToSend} setAssetSuite={setAssetSuite} setMessageSuite={setMessageSuite} btnText={btnText} btnClass={btnClass} /> : null}
          {assetsFilteredToSend != null && type == 'Richiesta Offerta Generica' ? <RenewalOffer assets={assetsFilteredToSend} setAssetOffer={setAssetOffer} setMessageOffer={setMessageOffer} btnText={btnText} btnClass={btnClass} /> : null}
        </form>
      </div>
    </div>
  )
}

export default RenewalRequest;
