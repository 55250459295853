import React, { useEffect, useState } from 'react';

import Icon from '../icons';
import MailBoxHelp from '../../components/mail-box-help';
import EditProfileData from '../../components/edit-profile-data';
import { getItems } from '../../functions/request';
import { useAuth } from '../../context/auth.context';

const FilterOpp = ({ setFilter = () => { } }) => {
    return (
        <div>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Stato</span>
            <select onChange={(e) => setFilter(e.target.value === '' ? null : e.target.value)} name="filter" id="filter" className="ml-auto form-control" style={{ fontSize: 12, width: 120 }}>
                <option value="">Nessuno</option>
                <option value="aperte">Aperte</option>
                <option value="vinte">Vinte</option>
                <option value="perse">Perse</option>
            </select>
        </div>
    )
}

const FilterAss = ({ setFilter = () => { } }) => {
    return (
        <div>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Stato</span>
            <select onChange={(e) => setFilter(e.target.value === '' ? null : e.target.value)} name="filter" id="filter" className="ml-auto form-control" style={{ fontSize: 12, width: 120 }}>
                <option value="">Nessuno</option>
                <option value="in-scadenza">In Scadenza</option>
                <option value="scaduti">Scaduti</option>
            </select>
        </div>
    )
}

const FilterQuo = ({ setFilter = () => { } }) => {
    return (
        <div>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Stato</span>
            <select onChange={(e) => setFilter(e.target.value === '' ? null : e.target.value)} name="filter" id="filter" className="ml-auto form-control" style={{ fontSize: 12, width: 120 }}>
                <option value="">Nessuno</option>
                <option value="Aperti">Aperti</option>
                <option value="Accettati">Accettati</option>
                <option value="Non-Accettati">Non accettati</option>
            </select>
        </div>
    )
}

const FilterOrd = ({ role = null, setFilter = () => { } }) => {
    return (
        <div>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Stato</span>
            <select onChange={(e) => setFilter(e.target.value === '' ? null : e.target.value)} name="filter" id="filter" className="ml-auto form-control" style={{ fontSize: 12, width: 120 }}>
                { role == 'CLIENT' ? null : <option value="">Nessuno</option> }
                <option value="Approvati">Approvati</option>
                { role == 'CLIENT' ? null : <option value="Sospesi">Sospesi</option> }
                { role == 'CLIENT' ? null : <option value="Cancellati">Cancellati</option> }
            </select>
        </div>
    )
}

const AssignedBy = ({ defaultId, handleSetAssigned }) => {
    const [users, setUsers] = useState([]);
    const { token } = useAuth();

    useEffect(() => {
        const getUsers = async () => {
            const data = await getItems('/users/list', token);
            // setUsers(data);
            setUsers([]);
        }

        getUsers();
    }, [])

    return (
        <div>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Agente</span>
            <select defaultValue={defaultId} onChange={(e) => handleSetAssigned(e.target.value == "null" ? null : e.target.value)} id="assigned" name="assigned" className="ml-1 form-control" style={{ fontSize: 12, width: 120 }}>
                <option value="null">Tutti</option>
                {
                    users.map((item, index) => {
                        return <option value={item.id} key={index}>{item.user_name}</option>
                    })
                }
            </select>
        </div>
    )
}

const TopBarPage = ({ showFilterAndOthers = true, showYear = true, setYear, showForAgent = true, showAssign = true, defaultId, handleChangeAssigned, searchQuery, page, searchBtn = true, placeholderInput, setFilterData, type = '', role = null }) => {
    const [show, setShow] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const [showEditData, setShowEditData] = useState(false);
    const [query, setQuery] = useState('')

    return (
        <div className="top-page">
            <h1 className="name-page">{page}</h1>

            { showFilterAndOthers && type == 'Opp' ? <FilterOpp setFilter={setFilterData} /> : null}
            { showFilterAndOthers && type == 'Ass' ? <FilterAss setFilter={setFilterData} /> : null}
            { showFilterAndOthers && type == 'Quo' ? <FilterQuo setFilter={setFilterData} /> : null}
            { showFilterAndOthers && type == 'Ord' ? <FilterOrd role={role} setFilter={setFilterData} /> : null}
            { showFilterAndOthers && role == 'ADMIN' ? <AssignedBy defaultId={defaultId} handleSetAssigned={handleChangeAssigned} /> : null}

            { showFilterAndOthers && showYear ?
                <div>
                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Anno</span>
                <select name="year" id="year" className="ml-1 form-control" style={{ fontSize: 12, width: 120 }} onChange={(e) => setYear(e.target.value == new Date().getFullYear() ? null : e.target.value)}>
                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                    <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                    <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                    <option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</option>
                </select></div> : null}

            { showFilterAndOthers && show ?
                <form onSubmit={(e) => searchQuery(e, query)} style={{ marginLeft: '10px', width: '20%' }}>
                    <input placeholder={placeholderInput} type="text" style={{ marginRight: 10, fontSize: 12 }} className="ml-auto form-control" onChange={(e) => setQuery(e.target.value)} />
                </form>
                : null}
            <div className="fun-icons">
                {showFilterAndOthers && searchBtn ? <a onClick={() => setShow(!show)} href="#" className="fun-item"><img className="fun-item-icon" src={Icon.icoSearch} alt="Search" /></a> : null}
                <a onClick={() => setShowMail(!showMail)} href="#" className="fun-item"><img className="fun-item-icon" src={Icon.icoHelp} alt="Help" /></a>
                <a onClick={() => setShowEditData(!showEditData)} href="#" className="fun-item"><img className="fun-item-icon" src={Icon.icoProfile} alt="Help" /></a>
            </div>

            {showMail ? <MailBoxHelp setShowMail={setShowMail} /> : null}
            {showEditData ? <EditProfileData setShowEditData={setShowEditData} /> : null}
        </div>
    )
}

export default TopBarPage;
