import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { useAuth } from '../../context/auth.context';

import './edit-profile-data.scss';

const EditProfileData = ({ setShowEditData }) => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [agency, setAgency] = useState("");
    const [pIva, setPIva] = useState("");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
  
    const { user, update } = useAuth();

    useEffect(() => {
        setName(user.firstName);
        setSurname(user.lastName);
        setAgency(user.agency);
        setPIva(user.pIva);
        setPhone(user.phone);
        setUsername(user.username);
        setEmail(user.email);
    }, [user])
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      setError(false);
      setSuccess(false);
      setMessage("");
  
      const data = {
        firstName: name,
        lastName: surname,
        agency: agency,
        pIva: pIva,
        phone: phone,
        email: email,
        username: username
      };
  
      const [ok, message] = await update(data);
  
      if (!ok) {
        setError(true);
        setSuccess(false);
      } else {
        setError(false);
        setSuccess(true);
      }
  
      setMessage(message);
    };

    return (
        <div className="edit-profile-data-wrapper">
            <div className="edit-profile-data">
                <div className="edit-profile-data-top">
                    <h2>Modifica Dati</h2>
                    <a href="#" onClick={(e) => { e.preventDefault(); setShowEditData(false); }}>&times;</a>
                </div>

                <hr/>

                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="3">
                            <label htmlFor="name">Nome</label>
                            <input className="form-control" type="text" id="name" defaultValue={name} onChange={ (e) => setName(e.target.value) } />
                        </Col>
                        <Col md="3">
                            <label htmlFor="surname">Cognome</label>
                            <input className="form-control" type="text" id="surname" defaultValue={surname} onChange={ (e) => setSurname(e.target.value) } />
                        </Col>
                        <Col md="3">
                            <label htmlFor="email">Email</label>
                            <input className="form-control" type="email" id="email" defaultValue={email} onChange={ (e) => setEmail(e.target.value) } />
                        </Col>
                        <Col md="3">
                            <label htmlFor="phone">Telefono</label>
                            <input className="form-control" type="text" id="phone" defaultValue={phone} onChange={ (e) => setPhone(e.target.value) } />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3">
                            <label htmlFor="name">Username</label>
                            <input className="form-control" type="text" id="username" defaultValue={username} onChange={ (e) => setUsername(e.target.value) } />
                        </Col>
                        <Col md="3">
                            <label htmlFor="surname">P. Iva</label>
                            <input className="form-control" type="text" id="piva" defaultValue={pIva} onChange={ (e) => setPIva(e.target.value) } />
                        </Col>
                        <Col md="3">
                            <label htmlFor="email">Azienda</label>
                            <input className="form-control" type="text" id="agency" defaultValue={agency} onChange={ (e) => setAgency(e.target.value) } />
                        </Col>
                    </Row>

                    <hr/>

                    <p>{message}</p>

                    <input type="submit" className={"btn btn-primary"} value={"Conferma"} />
                </form>
            </div>
        </div>
    )
}

export default EditProfileData;
