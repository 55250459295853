import React, { useEffect, useState } from "react";

// icons
import Icon from "../icons";
import "./sidebar.scss";

import { request } from "../../functions/request";
import { useAuth } from "../../context/auth.context";

function Sidebar() {
  const { firstName, lastName, user, role, accounts, logout } = useAuth();

  const handleSetAccount = async (e) => {
    // const val = e.target.value;

    // const data = await request("/api/user/accounts/edit?id=" + val, "get");
    // localStorage.setItem("agent", val);
    // window.location.reload();
  };

  if (user) {
    return (
      <div className="sidebar">
        <div className="sidebar-top">
          <p className="sidebar-top-user-auth">{`${firstName} ${lastName}`}</p>
          <a className="ml-auto" onClick={() => logout()}>
            <img
              src={Icon.icoLogout}
              alt="Logout Icon"
              className="sidebar-top-logo"
            />
          </a>
        </div>

        {role == "AGENT" && accounts != null ? (
          <>
            <hr />

            <select
              onChange={handleSetAccount}
              defaultValue={localStorage.getItem("agent")}
              className="form-control"
              name="accounts"
              id="accounts"
            >
              <option value="">Seleziona un'azienda</option>
              {accounts.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.accountname}
                  </option>
                );
              })}
            </select>
          </>
        ) : null}

        <hr />

        <div className="sidebar-menu">
          <a href="/" className="sidebar-item">
            <img
              src={Icon.icoDashboard}
              alt="Dashboard"
              className="sidebar-item-icon"
            />
            <span>Dashboard</span>
          </a>
          <a href="/status" className="sidebar-item">
            <img
              src={Icon.icoDashboard}
              alt="Dashboard"
              className="sidebar-item-icon"
            />
            <span>Stato</span>
          </a>

          {/* {role == 'ADMIN' ? <SidebarAdmin /> : null}
                {role == 'AGENT' && localStorage.getItem('agent') !== undefined && localStorage.getItem('agent') !== null ? <SidebarAdmin /> : null}
                {role == 'LEADS' ? <SidebarLeads /> : null}
                {role == 'CLIENT' ? <SidebarContacts /> : null} */}

          <a href={!user.canViewOpportunities ? '#' : "/opportunities"} className={user.canViewOpportunities ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoOp}
                alt="Opportunità"
                className="sidebar-item-icon"
              />
              <span>Opportunità</span>
            </a>
          <a href={!user.canViewQuotes ? '#' : "/preventivi"} className={user.canViewQuotes ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoPreventici}
                alt="Preventivi"
                className="sidebar-item-icon"
              />
              <span>Preventivi</span>
            </a>
          <a href={!user.canViewOrders ? '#' : "/ordini"} className={user.canViewOrders ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoCart}
                alt="Ordini"
                className="sidebar-item-icon"
              />
              <span>Ordini</span>
            </a>
          <a href={!user.canViewInvoices ? '#' : "/fatture"} className={user.canViewInvoices ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoFatture}
                alt="Fatture"
                className="sidebar-item-icon"
              />
              <span>Fatture</span>
            </a>
          <a href={!user.canViewAssets ? '#' : "/asset-e-scadenze"} className={user.canViewAssets ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoCalendar}
                alt="Asset e Scadenze"
                className="sidebar-item-icon"
              />
              <span>Asset e Scadenze</span>
            </a>
          <a href={!user.canViewLeads ? '#' : "/leads"} className={user.canViewLeads ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoLeads}
                alt="Leads"
                className="sidebar-item-icon"
              />
              <span>Leads / Contatti</span>
            </a>
          <a href={!user.canViewTickets ? '#' : "/tickets"} className={user.canViewTickets ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoHelp}
                alt="Tickets"
                className="sidebar-item-icon"
              />
              <span>Tickets Aperti</span>
            </a>
          <a href={!user.canViewTickets ? '#' : "/tickets/other"} className={user.canViewTickets ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoHelp}
                alt="Tickets Altri"
                className="sidebar-item-icon"
              />
              <span>Altri Tickets</span>
            </a>
          <a href={!user.canViewPublicDocuments ? '#' : "/documenti-pubblici?path="} className={user.canViewPublicDocuments ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoPublicDoc}
                alt="Documenti Pubblici"
                className="sidebar-item-icon"
              />
              <span>Documenti Pubblici</span>
            </a>
            {/* <a href={!user.canViewPublicDocuments ? '#' : "/documenti-privati"} className={user.canViewPublicDocuments ? "sidebar-item" : "sidebar-item disabled"}>
              <img
                src={Icon.icoPublicDoc}
                alt="Documenti Privati"
                className="sidebar-item-icon"
              />
              <span>Documenti Privati</span>
            </a> */}
          {user.role === 'ADMIN' && (
            <a href="/users" className="sidebar-item">
              <img
                src={Icon.icoLeads}
                alt="Utenti"
                className="sidebar-item-icon"
              />
              <span>Utenti</span>
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default Sidebar;
