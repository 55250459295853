import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { request, getItems, getItemByQuery, httpPost } from '../../functions/request';
import './renewal-request.scss';

const RenewalSuiteSingle = ({ asset = null, product = null, setMessageSuite, btnClass, btnText }) => {
  const [ser, setSer] = useState(null);
  const [lic, setLic] = useState(null);
  const [dat, setDat] = useState(null);

  useState(() => {
    if (asset == null) return;
    setSer(asset.serialnumber);
    setLic(asset.cf_1051);
    setDat(asset.data_scadenza);
  }, []);

  return (
    <>
      <hr />

      <label htmlFor="assets">Seleziona un prodotto</label>
      <select className="form-control" id="assets">
        <option value={`${asset.asset_no}:${product.productname}`}>{`${asset.data_scadenza} - ${product.productname}`}</option>
      </select>

      {
        ser !== null && lic !== null && dat !== null ?
          <>
            <hr />
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Nr. Seriale:</strong> {ser}</p>
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Nr. Licenza:</strong> {lic}</p>
            <p style={{ marginTop: 0, marginBottom: 5 }}><strong>Data Scadenza:</strong> {dat}</p>
            <hr />
          </>
          : null
      }

      <label htmlFor="message">Messaggio</label>
      <textarea name="message" id="message" className="form-control" rows="10" onChange={(e) => setMessageSuite(e.target.value)}></textarea>

      <hr />

      <input type="submit" className={`mt-2 ${btnClass}`} value={btnText} />
    </>
  )
};

const RenewalRequestSingle = ({ setShowRenewalReq, asset_no = null }) => {
  const [asset, setAsset] = useState(null);
  const [client, setClient] = useState(null);
  const [product, setProduct] = useState(null);
  const [messageSuite, setMessageSuite] = useState('');
  const [btnClass, setBtnClass] = useState('btn btn-secondary mt-2');
  const [btnText, setBtnText] = useState('Invia Richiesta');

  useEffect(() => {
    const getAssets = async () => {
      const data = await getItems(`/assets/single/${asset_no}`, null, null);
      setAsset(data.asset);
      setProduct(data.product);
      setClient(data.asset.cf_1053)
    }

    getAssets();
  }, [])

  const sendMail = async (e) => {
    e.preventDefault();

    setBtnText('Invio del messaggio in corso...');

    let senddata = {
      typereq: 'rinnovo',
      asset: `${asset.asset_no}:${product.productname}`,
      client: client,
      message: messageSuite
    }

    const data = await httpPost('/users/assistence/renewal', 'post', {}, { ...senddata });

    if (!data.ok) {
      setBtnClass('btn btn-danger mt-2');
    } else {
      setBtnClass('btn btn-success mt-2');
      return setShowRenewalReq(false);
    }

    setBtnText(data.message);
  }

  return (
    <div className="renewal-request-wrapper">
      {
        asset != null && product != null && client != null ?
          <div className="renewal-request">
            <div className="renewal-request-top">
              <h2>Richiesta di Rinnovo</h2>
              <a href="#" onClick={(e) => { e.preventDefault(); setShowRenewalReq(false); }}>&times;</a>
            </div>

            <hr />

            <form onSubmit={sendMail}>
              <label htmlFor="type">Seleziona il tipo di richiesta?</label>

              <div className="row">
                <div className="col-md-6">
                  <select className="form-control" type="text" id="type">
                    <option value="Rinnovo Suite">Rinnovo Suite</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <select className="form-control" id="client">
                    <option value={client}>{client}</option>
                  </select>
                </div>
              </div>

              <RenewalSuiteSingle
                asset={asset}
                product={product}
                setMessageSuite={setMessageSuite}
                btnText={btnText}
                btnClass={btnClass} />
            </form>
          </div>
          : <div className="loader-square"></div> }
    </div>
  )
}

export default RenewalRequestSingle;
