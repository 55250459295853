import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row, Container, Alert, Input } from "reactstrap";
import qs from "qs";

import Icon from "../ui-components/icons";
import { useAuth } from "../context/auth.context";
import { httpGet, httpPost } from "../functions/request";
import { useLocation } from "react-router-dom";

const Recovery = () => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "Dashboard - CIPS";

    if (params.session === undefined) return;

    const fetchSession = async () => {
      const result = await httpGet(`/auth/security/session/${params.session}`, {}, null);
      if (!result.ok) return (window.location.href = "/login");
    };

    fetchSession();
  }, [params]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSuccess(false);
    setError(false);
    setMessage("");

    if (params.session === undefined) return;

    if (password !== confirmPassword) {
      setSuccess(false);
      setError(true);
      return setMessage("Password e Conferma Password devono essere uguali.");
    }

    const result = await httpPost("/auth/recovery/password", {
      sessionUuid: params.session,
      password: password,
    });

    if (result.ok) return (window.location.href = "/login");

    setSuccess(false);
    setError(true);
    return setMessage(result.result);
  };

  return (
    <div className="recovery">
      <Container fluid={true} className="home-page-user-auth">
        <Row>
          <Col sm="6" className="cips-logo">
            <div className="cips-logo-copy">
              <img
                src={Icon.icoCips}
                alt="Logo CIPS"
                style={{
                  width: "100%",
                  maxWidth: 350,
                }}
              />
              <p className="cips-logo-copy-title">Area Riservata</p>
              <p className="cips-logo-copy-text">Effettua l'accesso o registrati</p>
            </div>
          </Col>
          <Col sm="6" className="cips-login-form" style={{ background: "#fff" }}>
            <form onSubmit={handleSubmit} className="w-100">
              <p className="cips-title">Recupera Password</p>

              <label htmlFor="password">
                Password <span className="required">*</span>
              </label>
              <Input onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" />

              <label htmlFor="confirm-password">
                Conferma Password <span className="required">*</span>
              </label>
              <Input
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                name="confirm-password"
                id="confirm-password"
              />

              <input type="submit" value="Conferma" className="btn btn-primary btn-block" />

              {error ? (
                <Alert color="danger" className="font-weight-bold mt-3">
                  {message}
                </Alert>
              ) : (
                <></>
              )}

              {success ? (
                <Alert color="success" className="font-weight-bold" style={{ marginTop: 10 }}>
                  {message}
                </Alert>
              ) : (
                <></>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Recovery;
