
import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import TopBarPage from "../ui-components/top-bar-page";
import { getItems } from "../functions/request";
import { useAuth } from "../context/auth.context";
import { useParams } from "react-router-dom";
import moment from "moment";

const TicketSingle = (props) => {
  const { id } = useParams();

//   {
//     "ticket": {
//         "ticket_title": "Customer cittametropolitana.ve.it - Huge delay in email delivery after user creation",
//         "parent_id": "3x5968",
//         "assigned_user_id": "19x22",
//         "cf_648_2338": "17-03-2023",
//         "cf_648_2339": "HornetSecurity",
//         "cf_648_2340": "1834592",
//         "ticketpriorities": "Low",
//         "product_id": "",
//         "ticketseverities": "Minor",
//         "ticketstatus": "Closed",
//         "createdtime": "2023-03-17 09:57:51",
//         "update_log": "",
//         "hours": "0.50",
//         "days": "0.00",
//         "ticketcategories": "Post-vendita",
//         "ticket_no": "TT4694",
//         "modifiedtime": "2023-03-20 15:07:15",
//         "projecttaskid": "",
//         "projectplanid": "",
//         "creator": {
//             "user_name": "vconti",
//             "use_ldap": "0",
//             "is_admin": "on",
//             "user_password": "$1$vc000000$xnYOuZnGByBTC0SZGJFRL.",
//             "email1": "veronica.conti@cips.it",
//             "confirm_password": "$1$vc000000$xnYOuZnGByBTC0SZGJFRL.",
//             "status": "Active",
//             "first_name": "Veronica",
//             "currency_id": "22x1",
//             "last_name": "Conti",
//             "lead_view": "Today",
//             "roleid": "H2",
//             "menu_view": "Large Menu",
//             "default_module": "Home",
//             "default_language": "it_it",
//             "default_theme": "next22",
//             "dark_mode": "1",
//             "allow_generic_talks": "1",
//             "receive_public_talks": "1",
//             "notify_me_via": "ModNotifications",
//             "user_timezone": "Europe/Rome",
//             "notify_summary": "Never",
//             "enable_activesync": "0",
//             "external_code": "",
//             "title": "",
//             "phone_fax": "",
//             "department": "14",
//             "email2": "",
//             "phone_work": "",
//             "phone_mobile": "",
//             "skype": "",
//             "reports_to_id": "",
//             "phone_home": "",
//             "phone_other": "",
//             "description": "",
//             "internal_mailer": "1",
//             "decimal_separator": ".",
//             "thousands_separator": "",
//             "decimals_num": "2",
//             "address_street": "",
//             "address_country": "",
//             "address_city": "",
//             "address_postalcode": "",
//             "address_state": "",
//             "avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "activity_view": "This Week",
//             "date_format": "dd-mm-yyyy",
//             "reminder_interval": "None",
//             "hour_format": "24",
//             "start_hour": "07:00",
//             "end_hour": "",
//             "no_week_sunday": "1",
//             "weekstart": "1",
//             "cal_color": "e83ab5e83ab5",
//             "is_resource": "0",
//             "holiday_countries": "IT",
//             "asterisk_extension": "",
//             "use_asterisk": "0",
//             "exchange_username": "",
//             "exchange_password": "20!f9a2-e8e4",
//             "exchange_sync_ldap": "0",
//             "exchangeusername": "",
//             "exchangepassword": "",
//             "exchange_ldap": "0",
//             "exchange_sync_date": "09-04-2021",
//             "exchangefolders": "",
//             "bm_status": "0",
//             "bm_function_mode": "BmSemiautomatic",
//             "bm_event_duration": "30",
//             "bm_min_time_frame": "60",
//             "bm_compose_email_default": "0",
//             "accesskey": "wMlwONK03Y4WVx",
//             "accesskey_validity": "AccessKeyValidityStatic",
//             "id": "19x22"
//         },
//         "mailscanner_action": "0",
//         "description": "I would like to request you a check about activation time of new email addresses and aliases for antispam protection service.\r\nCustomer “cittametropolitana.ve.it” has done some test switching “User check” from “SMTP” to “Control Panel” but has experienced a delay of almost 4/6 hours before that they can start receiving emails into Destination Server.\r\nConsider that they have MS Exchange on premise and email addresses were already there when they have created users into control panel.\r\n\r\nBelow some details:\r\n-\tOne test has been done on 13th of March with alias giorgio59.ossena@cittametropolitana.ve.it (added to user giorgio.ossena@cittametropolitana.ve.it) and new mailbox prova.informatica@cittametropolitana.ve.it , email where always rejected with error 554 5.7.1\r\n-\tAnother test has been done with mailbox rappresentate.volontaripc@cittametropolitana.ve.it , account has been created on 10th March at 8:44 a.m. and after 2 hours messages were not delivered. They have switched back to SMTP and messages have been delivered\r\n-\tLast test has been done with mailbox prova55.informatica66@cittametropolitana.ve.it (now deleted) created on 13th March at 12:00 p.m. that has started receiving emails only around at 6:00 p.m.\r\n\r\nSome investigations has been done from the partner and they tell me that on Friday 10th March the customer has started also a spam test towards their domain.\r\n\r\nPlease let me know if you need any other details.",
//         "solution": "Yes, if after 2 hours from creation the user has not received any mail, you can consult us so that we can check if it is a issue with the user or if it is due to the load on the servers.",
//         "time_elapsed": "0",
//         "time_remaining": "0",
//         "start_sla": "2023-03-17 09:57:51",
//         "end_sla": "0000-00-00 00:00:00",
//         "time_refresh": "2023-03-17 09:57:51",
//         "sla_time": "0",
//         "due_date": "17-03-2023",
//         "due_time": "",
//         "reset_sla": "0",
//         "ended_sla": "0",
//         "time_elapsed_idle": "0",
//         "time_elapsed_out_sla": "0",
//         "signature": "",
//         "email_from": "",
//         "email_to": "",
//         "email_cc": "",
//         "email_bcc": "",
//         "helpdesk_from_name": "",
//         "helpdesk_from": "",
//         "email_date": "",
//         "id": "9x300648",
//         "assigned": {
//             "user_name": "vconti",
//             "use_ldap": "0",
//             "is_admin": "on",
//             "user_password": "$1$vc000000$xnYOuZnGByBTC0SZGJFRL.",
//             "email1": "veronica.conti@cips.it",
//             "confirm_password": "$1$vc000000$xnYOuZnGByBTC0SZGJFRL.",
//             "status": "Active",
//             "first_name": "Veronica",
//             "currency_id": "22x1",
//             "last_name": "Conti",
//             "lead_view": "Today",
//             "roleid": "H2",
//             "menu_view": "Large Menu",
//             "default_module": "Home",
//             "default_language": "it_it",
//             "default_theme": "next22",
//             "dark_mode": "1",
//             "allow_generic_talks": "1",
//             "receive_public_talks": "1",
//             "notify_me_via": "ModNotifications",
//             "user_timezone": "Europe/Rome",
//             "notify_summary": "Never",
//             "enable_activesync": "0",
//             "external_code": "",
//             "title": "",
//             "phone_fax": "",
//             "department": "14",
//             "email2": "",
//             "phone_work": "",
//             "phone_mobile": "",
//             "skype": "",
//             "reports_to_id": "",
//             "phone_home": "",
//             "phone_other": "",
//             "description": "",
//             "internal_mailer": "1",
//             "decimal_separator": ".",
//             "thousands_separator": "",
//             "decimals_num": "2",
//             "address_street": "",
//             "address_country": "",
//             "address_city": "",
//             "address_postalcode": "",
//             "address_state": "",
//             "avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "activity_view": "This Week",
//             "date_format": "dd-mm-yyyy",
//             "reminder_interval": "None",
//             "hour_format": "24",
//             "start_hour": "07:00",
//             "end_hour": "",
//             "no_week_sunday": "1",
//             "weekstart": "1",
//             "cal_color": "e83ab5e83ab5",
//             "is_resource": "0",
//             "holiday_countries": "IT",
//             "asterisk_extension": "",
//             "use_asterisk": "0",
//             "exchange_username": "",
//             "exchange_password": "20!f9a2-e8e4",
//             "exchange_sync_ldap": "0",
//             "exchangeusername": "",
//             "exchangepassword": "",
//             "exchange_ldap": "0",
//             "exchange_sync_date": "09-04-2021",
//             "exchangefolders": "",
//             "bm_status": "0",
//             "bm_function_mode": "BmSemiautomatic",
//             "bm_event_duration": "30",
//             "bm_min_time_frame": "60",
//             "bm_compose_email_default": "0",
//             "accesskey": "wMlwONK03Y4WVx",
//             "accesskey_validity": "AccessKeyValidityStatic",
//             "id": "19x22"
//         },
//         "parent": {
//             "accountname": "CHIP SPACE S.R.L.",
//             "cf_2040": "",
//             "account_no": "ACC766",
//             "cf_2041": "",
//             "cf_2042": "",
//             "assigned_user_id": "19x25",
//             "phone": "041-5950465",
//             "cf_2043": "",
//             "cf_2045": "",
//             "industry": "System Integrator",
//             "cf_gll_2466": "",
//             "fax": "041-5950467",
//             "accounttype": "RIVENDITORE",
//             "otherphone": "",
//             "cf_2038": "",
//             "email1": "amministrazione@chipcomputers.it",
//             "annual_revenue": "0",
//             "email2": "",
//             "ownership": "",
//             "website": "",
//             "account_id": "",
//             "crmv_vat_registration_number": "02179570276",
//             "cf_2053": "867",
//             "external_code": "103000867",
//             "crmv_bankdetails": "",
//             "rating": "ATTIVO",
//             "crmv_social_security_number": "02179570276",
//             "createdtime": "2019-11-07 00:00:00",
//             "newsletter_unsubscrpt": "1",
//             "cf_1846": "contatto passato da Loris Angeloni (Computerlinks)",
//             "modifiedtime": "2022-11-23 00:00:00",
//             "cf_1875": "Public Relation",
//             "creator": "19x1",
//             "cf_2039": "",
//             "cf_1848": "0",
//             "cf_2091": "11",
//             "daily_cost": "0.00",
//             "cf_648_2352": "0",
//             "cf_gll_2460": "NO",
//             "bill_street": "VIA DELLA STAZIONE 1/B",
//             "ship_street": "VIA DELLA STAZIONE 1/B",
//             "bill_city": "MARCON",
//             "ship_city": "MARCON",
//             "bill_state": "VE",
//             "ship_state": "VE",
//             "bill_code": "30020",
//             "ship_code": "30020",
//             "bill_country": "ITALIA",
//             "ship_country": "ITALIA",
//             "cf_1901": "0.00",
//             "cf_1904": "02-09-2022",
//             "cf_1902": "5131.88",
//             "cf_1905": "02-09-2022",
//             "cf_1903": "5131.88",
//             "cf_850": "0",
//             "cf_865": "1",
//             "cf_888": "R.B. 60gg DFFM",
//             "cf_1770": "4186",
//             "description": "",
//             "id": "3x5968"
//         }
//     },
//     "comments": [
//         {
//             "commentid": 2177,
//             "ticketid": 300648,
//             "comment": "just to summarize, usually activation time can be around 2 hours and if exceeded we should open a ticket to ask a deeper investigation, is it correct?",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679061575,
//             "sequence": 8
//         },
//         {
//             "commentid": 2176,
//             "ticketid": 300648,
//             "comment": "Jonathan\nWe had some load on the servers, so we have taken some more time to propagate the usernames to all servers.\n \nThe server is now fully propagated and can receive external mails.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679061563,
//             "sequence": 7
//         },
//         {
//             "commentid": 2175,
//             "ticketid": 300648,
//             "comment": "Jonathan\nI see that all the test emails I have sent to the user UPSMON@cittametropolitana.ve.it have been rejected with error 554 5.7.1 Domain/User unknown.\n \nAfter 2 hours since its creation in the control panel, the emails are still rejected, so I will escalate the case internally so that they can analyze the reasons why the user still can not receive emails.\n \nI will let you know with any news.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679061549,
//             "sequence": 6
//         },
//         {
//             "commentid": 2171,
//             "ticketid": 300648,
//             "comment": "Jonathan \nThank you for your email.\n \nI have verified that the user you indicate has been created in the control panel, so every 15 minutes I will send a test email to the account to let you know how long it has taken to be operational.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679048778,
//             "sequence": 5
//         },
//         {
//             "commentid": 2170,
//             "ticketid": 300648,
//             "comment": "customer confirmed that is available for this test!\nHas created into Control Panel right now mailbox UPSMON@cittametropolitana.ve.it that was already existing into MS Exchange.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679048769,
//             "sequence": 4
//         },
//         {
//             "commentid": 2169,
//             "ticketid": 300648,
//             "comment": "Jonathan\nOf course, let me know when the user has been created, along with their address, and I will send test emails and check how long it takes to become operational.\n \nIf it takes more than 2 hours for the user to be able to receive these emails, I can escalate the case internally to investigate why the user has not been created.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679047004,
//             "sequence": 3
//         },
//         {
//             "commentid": 2168,
//             "ticketid": 300648,
//             "comment": "if you cannot do any analysis on what happens during this week I can ask to the customer to do a test today.\nWould it be possible in this case to check activation time together?\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679046997,
//             "sequence": 2
//         },
//         {
//             "commentid": 2167,
//             "ticketid": 300648,
//             "comment": "Jonathan \nUser activation times are 2 hours maximum, but if during this time you make changes to the \"user check\" this may affect the user, as well as other possible inconveniences that may take longer to create the user.\n \nWhen you come across a user that has been created manually in the control panel more than 2 hours ago, but is not receiving mail, please let us know and do not delete that user or modify the registration mode, so that we can check the current status of the user that has not received mails after 2 hours since its creation.\n",
//             "ownertype": "user",
//             "assigned_user_id": 22,
//             "author_id": 22,
//             "author": "Veronica Conti",
//             "author_avatar": "storage/2021/May/week4/213768_Evento_Milano.jpg",
//             "employee_type": null,
//             "timestamp": 1679045907,
//             "sequence": 1
//         }
//     ]
// }
  const [data, setData] = useState(null);
  const [comments, setComments] = useState(null);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(12);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [assignedId, setAssignedId] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());

  const { role, token, user } = useAuth();

  useEffect(() => {
    document.title = "Tickets - CIPS";
    if (!user.canViewTickets) window.location.href = "/";
  }, [user]);

  const callData = async () => {
    let data = await getItems(`/tickets/single/${id}`, token, null, null, {});
    console.log(data)
    setData(data.results.ticket);
    setComments(data.results.comments)
  }

  useEffect(() => {
    callData();
  }, [id])

  return (
    <div style={{ overflow: 'scroll' }} className="single-tickets">
      <TopBarPage showYear={false} showFilterAndOthers={false} page="Overview ticket" />

      <hr />

      <a href="javascript:history.go(-1)" className="badge badge-secondary mb-2">Torna Indietro</a>

      { data !== null ? (
        <div className="form-panel mt-2">
          <div className="row">
            <div className="col-md-6">
              <p className="text-bold">Titolo</p>
              <p className="text-normal">{data.ticket_title}</p>
            </div>
            <div className="col-md-6">
              <p className="text-bold">Numero</p>
              <p className="text-normal">{data.ticket_no}</p>
            </div>
            <div className="col-md-6">
              <p className="text-bold">Priorità</p>
              <p className="text-normal">{data.ticketpriorities}</p>
            </div>
            <div className="col-md-6">
              <p className="text-bold">Stato</p>
              <p className="text-normal">{data.ticketstatus}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p className="text-bold">Categoria</p>
              <p className="text-normal">{data.ticketcategories}</p>
            </div>
            <div className="col-md-6">
              <p className="text-bold">Creato il</p>
              <p className="text-normal">{moment(data.createdtime).format('DD/MM/YYYY')}</p>
            </div>
            <div className="col-md-6">
              <p className="text-bold">Modificato il</p>
              <p className="text-normal">{moment(data.modifiedtime).format('DD/MM/YYYY')}</p>
            </div>
          </div>

          <hr />

          <p className="text-bold">Descrizione</p>
          <p className="text-normal">{data.description}</p>

          <p className="text-bold">Soluzione</p>
          <p className="text-normal">{data.solution}</p>
        </div>
      ) : null}

      { comments !== null ? (
        <div className="comments-tickets">
          <hr />
          
          <h2>Commenti</h2>

          {
            comments.map((item, idx) => {
              return (
                <div className="comment-item" key={idx}>
                  <p className="author">{item.author} - <span className="time">{moment(item.timestamp).format("DD/MM/YYYY hh:mm")}</span></p>
                  <p className="comment">{item.comment}</p>
                </div>
              )
            })
          }
        </div>
      ) : null}

    </div>
  );
};

export default TicketSingle;
