import React, { useEffect, useState } from "react";

import { httpGet, request } from "../../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./configurator.scss";
import { useAuth } from "../../context/auth.context";
import { httpPost } from "../../functions/request";

const Configurator = ({ setShowConf = () => {} }) => {
  const { role, token } = useAuth();

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);

  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState(null);
  const [product, setProduct] = useState(null);

  const [qta, setQta] = useState(1);
  const [singlePrice, setSinglePrice] = useState(0);
  const [total, setTotal] = useState(0);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState(0);
  const [tot, setTot] = useState(0);

  useEffect(() => {
    const fetchBrands = async () => {
      const brands = await httpGet("/configurator/brands", {}, token);
      setBrands(brands.results);
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await httpGet(
        `/configurator/products/${brand}`,
        {},
        token
      );
      setProducts(products.results);
    };

    if (brand != null) {
      fetchProducts();
    }
  }, [brand]);

  useEffect(() => {
    if (productId == null) {
      return;
    }

    let single = products.filter((e) => e.id === productId);
    single = single[0];

    let unitPrice = parseFloat(single.unit_price);
    console.log(unitPrice);

    setSinglePrice(unitPrice);
    setTotal(qta * unitPrice);
    setProduct(single);
  }, [productId]);

  useEffect(() => {
    if (product == null) {
      return;
    }

    let unitPrice = singlePrice;
    setTotal(qta * unitPrice);
  }, [qta]);

  const handleAddProduct = async () => {
    const newProduct = {
      id: productId,
      name: product.productname,
      qta: qta,
      brand: brand,
      unitPrice: singlePrice,
      total: total,
    };

    setSelectedProducts((old) => [...old, newProduct]);

    setTot((t) => total + t);
    setBrand(null);
    setProducts([]);
    setProductId(null);
    setProduct(null);
    setQta(1);
    setSinglePrice(0);
    setTotal(0);
  };

  const handleSendRequest = async () => {
    const data = await httpPost(
      "/configurator/request",
      {
        products: selectedProducts,
        total: tot,
      },
      token
    );

    if (!data.ok) {
      return alert(data.results);
    }

    setStep(2);
  };

  const handleSendCancelRequest = async (e) => {
    e.preventDefault();

    if (step == 2) return setShowConf(false);

    setShowConf(false);

    if (selectedProducts.length > 0) {
      const data = await httpPost(
        "/configurator/request-cancel",
        {
          products: selectedProducts,
          total: tot,
          step: step,
        },
        token
      );

      if (!data.ok) {
        return console.log(data.results);
      }
    }
  };

  const handleRemoveItem = async (index) => {
    const totalItem = selectedProducts.filter((e, i) => i === index)[0].total;
    setSelectedProducts((items) => items.filter((_, i) => i !== index));
    setTot((t) => t - totalItem);
  };

  const handleSendContinueRequest = async () => {
    setStep(1);

    const data = await httpPost(
      "/configurator/request-not-completed",
      {
        products: selectedProducts,
        total: tot,
      },
      token
    );

    if (!data.ok) {
      return console.log("failed");
    }
  };

  return (
    <div className="renewal-request-wrapper">
      <div className="renewal-request renewal-request-flex">
        <div className="renewal-request-top">
          <h2>Configuratore</h2>
          <a href="#" disabled={disabled} onClick={handleSendCancelRequest}>
            &times;
          </a>
        </div>

        {step == 0 ? (
          <div className="step-1">
            <hr />

            <label htmlFor="brand">Seleziona un brand</label>
            <select
              name="brand"
              id="brand"
              className="form-control"
              value={brand == null ? "" : brand}
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value="">Seleziona un brand per procedere</option>
              {brands.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>

            {brand !== null && products.length > 0 ? (
              <div className="select-product">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="product">Prodotto</label>
                    <select
                      name="product"
                      id="product"
                      className="form-control"
                      onChange={(e) => setProductId(e.target.value)}
                    >
                      <option value="">Nessun prodotto selezionato</option>
                      {products.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.productname}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="qta-product">Quantità</label>
                    <div className="d-flex">
                      <button
                        type="button"
                        style={{ border: "0", padding: 10, marginRight: 5 }}
                        className="badge btn-primary"
                        onClick={() => setQta(qta - 1)}
                      >
                        -
                      </button>
                      <input
                        value={qta}
                        onChange={(e) => setQta(parseInt(e.target.value))}
                        type="number"
                        className="form-control"
                        step="1"
                        min="1"
                        max="100"
                        id="qta-product"
                        name="qta-product"
                      />
                      <button
                        type="button"
                        style={{ border: "0", padding: 10, marginLeft: 5 }}
                        className="badge btn-primary"
                        onClick={() => setQta(qta + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="">Totale</label>
                    <p className="font-weight-bold">
                      &euro; {parseFloat(total).toFixed(2)}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="">&nbsp;</label>
                    <button
                      onClick={handleAddProduct}
                      type="button"
                      className="btn btn-primary w-100"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
                <label htmlFor="product"></label>
              </div>
            ) : null}
          </div>
        ) : null}

        {step == 1 ? (
          <div className="step-1">
            <hr />

            <h4 className="fw-bold mb-3">Riepilogo dei Prodotti</h4>

            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Nome Prodotto</th>
                  <th>Quantità</th>
                  <th>Prezzo Unitario</th>
                  <th>Totale</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.qta}</td>
                      <td>&euro; {parseFloat(item.unitPrice).toFixed(2)}</td>
                      <td>&euro; {parseFloat(item.total).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <p>
              Totale: <strong>&euro; {parseFloat(tot).toFixed(2)}</strong>
            </p>
          </div>
        ) : null}

        {step == 2 ? (
          <div className="step-2" style={{ textAlign: "center" }}>
            <hr />

            <h2 style={{ fontWeight: "bold", marginTop: 20 }}>
              La tua richiesta è stata inoltrata con successo!
            </h2>
            <p style={{ fontSize: 25, marginBottom: 50 }}>
              Ti ringraziamo per aver utilizzato il nostro configuratore. Verrai
              ricontattato da un nostro esperto entro 48 ore lavorative.
            </p>

            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Nome Prodotto</th>
                  <th>Quantità</th>
                  <th>Prezzo Unitario</th>
                  <th>Totale</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.qta}</td>
                      <td>&euro; {parseFloat(item.unitPrice).toFixed(2)}</td>
                      <td>&euro; {parseFloat(item.total).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <p>
              Totale: <strong>&euro; {parseFloat(tot).toFixed(2)}</strong>
            </p>
          </div>
        ) : null}

        {selectedProducts.length > 0 ? (
          <>
            {step == 0 ? (
              <>
                <hr />

                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Nome Prodotto</th>
                      <th>Quantità</th>
                      <th>Prezzo Unitario</th>
                      <th>Totale</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.qta}</td>
                          <td>
                            &euro; {parseFloat(item.unitPrice).toFixed(2)}
                          </td>
                          <td>&euro; {parseFloat(item.total).toFixed(2)}</td>
                          <td>
                            <a
                              href="#"
                              onClick={() => handleRemoveItem(index)}
                              className="btn btn-danger"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <p>
                  Totale: <strong>&euro; {parseFloat(tot).toFixed(2)}</strong>
                </p>

                <hr />

                <input
                  type="button"
                  style={{ alignSelf: "flex-start" }}
                  onClick={handleSendContinueRequest}
                  className="btn btn-primary"
                  value="Continua"
                />
              </>
            ) : null}
            {step == 1 ? (
              <>
                <hr />
                <input
                  type="button"
                  onClick={() => setStep(0)}
                  style={{ alignSelf: "flex-start" }}
                  className="btn btn-secondary"
                  value="Aggiungi altri prodotti"
                />
              </>
            ) : null}
            {step == 1 && selectedProducts.length > 0 ? (
              <>
                <input
                  style={{ fontSize: 13, alignSelf: "flex-start" }}
                  type="button"
                  onClick={handleSendRequest}
                  className="btn btn-primary mt-1"
                  value="Finalizza"
                />
              </>
            ) : null}
          </>
        ) : null}

        <p
          className={
            selectedProducts.length >= 3 ? "text-min no-absolute" : "text-min"
          }
        >
          Il prezzo di listino ha valore puramente indicativo e non costituisce
          offerta contrattuale. Per un’offerta dettagliata, comprensiva di promo
          o scontistica dedicata, è necessario rivolgersi al proprio account
          CIPS Informatica. Per i vendor non presenti, fai una richiesta al tuo
          account dedicato.
        </p>
      </div>
    </div>
  );
};

export default Configurator;
