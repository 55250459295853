import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProviders } from "./context";

import './sass/app.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>
);

reportWebVitals();
