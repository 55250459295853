import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import TopBarPage from "../ui-components/top-bar-page";
import { getItems, httpDelete, httpGet, httpPut } from "../functions/request";
import { useAuth } from "../context/auth.context";
import icons from "../ui-components/icons";
import { useParams } from "react-router-dom";

const UsersSingle = (props) => {
  const { id } = useParams();

  const [accounts, setAccounts] = useState(null);
  const [data, setData] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [agency, setAgency] = useState(null);
  const [pIva, setPIva] = useState(null);
  const [phone, setPhone] = useState(null);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [roleUser, setRole] = useState(null);
  const [status, setStatus] = useState(null);
  const [vteAssigned, setVteAssigned] = useState(null);
  const [canViewAccounts, setCanViewAccounts] = useState(null);
  const [canViewAssets, setCanViewAssets] = useState(null);
  const [canViewConfigurator, setCanViewConfigurator] = useState(null);
  const [canViewInvoices, setCanViewInvoices] = useState(null);
  const [canViewLeads, setCanViewLeads] = useState(null);
  const [canViewOpportunities, setCanViewOpportunities] = useState(null);
  const [canViewOrders, setCanViewOrders] = useState(null);
  const [canViewPublicDocuments, setCanViewPublicDocuments] = useState(null);
  const [canViewQuotes, setCanViewQuotes] = useState(null);
  const [canViewTickets, setCanViewTickets] = useState(null);
  const [canViewUsers, setCanViewUsers] = useState(null);

  const { role, token } = useAuth();

  useEffect(() => {
    document.title = "Users - CIPS";
  }, []);

  const callData = async () => {
    if (id == null || id == "") return;

    setData(null);

    let data = await httpGet(`/users/single/${id}`, {}, token);
    let accounts = await httpGet(`/accounts/list?limit=25000`, {}, token);

    setData(data.results);
    setAccounts(accounts.results.items);
    setFirstName(data.results.firstName);
    setLastName(data.results.lastName);
    setAgency(data.results.agency);
    setPIva(data.results.pIva);
    setPhone(data.results.phone);
    setUsername(data.results.username);
    setEmail(data.results.email);
    setRole(data.results.role);
    setStatus(data.results.status);
    setVteAssigned(data.results.vteAssigned);
    setCanViewAccounts(data.results.canViewAccounts);
    setCanViewAssets(data.results.canViewAssets);
    setCanViewConfigurator(data.results.canViewConfigurator);
    setCanViewInvoices(data.results.canViewInvoices);
    setCanViewLeads(data.results.canViewLeads);
    setCanViewOpportunities(data.results.canViewOpportunities);
    setCanViewOrders(data.results.canViewOrders);
    setCanViewPublicDocuments(data.results.canViewPublicDocuments);
    setCanViewQuotes(data.results.canViewQuotes);
    setCanViewTickets(data.results.canViewTickets);
    setCanViewUsers(data.results.canViewUsers);
  };

  useEffect(() => {
    callData();
  }, []);

  const handleUpdateData = async (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      agency,
      pIva,
      phone,
      username,
      email,
      role: roleUser,
      status: parseInt(status),
      vteAssigned,
      canViewAccounts,
      canViewAssets,
      canViewConfigurator,
      canViewInvoices,
      canViewLeads,
      canViewOpportunities,
      canViewOrders,
      canViewPublicDocuments,
      canViewQuotes,
      canViewTickets,
      canViewUsers,
    };

    const result = await httpPut(`/users/single/${id}`, data, token);

    if (!result.ok) {
      return alert(result.error);
    }

    window.location.reload();
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const result = await httpDelete(`/users/single/${id}`, {}, token);

    if (!result.ok) {
      return alert(result.error);
    }

    window.location.href = "/users";
  };

  return (
    <div>
      <TopBarPage page="Utenti" showFilterAndOthers={false} role={role} />

      <hr />

      {data != null && accounts != null ? (
        <div style={{ overflow: "auto" }} className="content-page">
          <form onSubmit={handleUpdateData}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="firstName">Nome</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Mario"
                    defaultValue={firstName === null ? null : firstName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="lastName">Cognome</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Rossi"
                    defaultValue={lastName === null ? null : lastName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="agency">Agenzia</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setAgency(e.target.value)}
                    type="text"
                    name="agency"
                    id="agency"
                    placeholder="CIPS"
                    defaultValue={agency === null ? null : agency}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="pIva">P. Iva</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setPIva(e.target.value)}
                    type="text"
                    name="pIva"
                    id="pIva"
                    placeholder="0123456789"
                    defaultValue={pIva === null ? null : pIva}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone">Telefono</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="+393231233321"
                    defaultValue={phone === null ? null : phone}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="username">Username</label>
                  <input
                    className="mb-2 form-control"
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    name="username"
                    id="username"
                    placeholder="mariorossi99"
                    defaultValue={username === null ? null : username}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="mario.rossi@domain.com"
                    defaultValue={email === null ? null : email}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Ruolo</label>
                  <select
                    className="mb-2 form-control"
                    onChange={(e) => setRole(e.target.value)}
                    defaultValue={roleUser === null ? "" : roleUser}
                    name="role"
                    id="role"
                  >
                    <option value="">Seleziona un opzione</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="CLIENT">CLIENT</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Stato</label>
                  <select
                    className="mb-2 form-control"
                    onChange={(e) => setStatus(e.target.value)}
                    defaultValue={status === null ? "" : status}
                    name="status"
                    id="status"
                  >
                    <option value="">Seleziona un opzione</option>
                    <option value="0">IN ATTESA</option>
                    <option value="1">ATTIVO</option>
                    <option value="2">BLOCCATO</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="">Assegnato a</label>
                  <select
                    className="mb-2 form-control"
                    onChange={(e) => setVteAssigned(e.target.value)}
                    defaultValue={vteAssigned === null ? "" : vteAssigned}
                    name="vteAssigned"
                    id="vteAssigned"
                  >
                    <option value="">Seleziona un opzione</option>
                    {accounts.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.accountname}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="canViewAccounts">
                    Accounts{" "}
                    <input
                      onChange={() => setCanViewAccounts(!canViewAccounts)}
                      type="checkbox"
                      name="canViewAccounts"
                      id="canViewAccounts"
                      defaultChecked={canViewAccounts === null ? false : canViewAccounts}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewAssets">
                    Assets{" "}
                    <input
                      onChange={() => setCanViewAssets(!canViewAssets)}
                      type="checkbox"
                      name="canViewAssets"
                      id="canViewAssets"
                      defaultChecked={canViewAssets === null ? false : canViewAssets}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewConfigurator">
                    Configuratore{" "}
                    <input
                      onChange={() => setCanViewConfigurator(!canViewConfigurator)}
                      type="checkbox"
                      name="canViewConfigurator"
                      id="canViewConfigurator"
                      defaultChecked={canViewConfigurator === null ? false : canViewConfigurator}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="canViewInvoices">
                    Fatture{" "}
                    <input
                      onChange={() => setCanViewInvoices(!canViewInvoices)}
                      type="checkbox"
                      name="canViewInvoices"
                      id="canViewInvoices"
                      defaultChecked={canViewInvoices === null ? false : canViewInvoices}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewLeads">
                    Utenti Vte{" "}
                    <input
                      onChange={() => setCanViewLeads(!canViewLeads)}
                      type="checkbox"
                      name="canViewLeads"
                      id="canViewLeads"
                      defaultChecked={canViewLeads === null ? false : canViewLeads}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewOpportunities">
                    Opportunità{" "}
                    <input
                      onChange={() => setCanViewOpportunities(!canViewOpportunities)}
                      type="checkbox"
                      name="canViewOpportunities"
                      id="canViewOpportunities"
                      defaultChecked={canViewOpportunities === null ? false : canViewOpportunities}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="canViewOrders">
                    Ordini{" "}
                    <input
                      onChange={() => setCanViewOrders(!canViewOrders)}
                      type="checkbox"
                      name="canViewOrders"
                      id="canViewOrders"
                      defaultChecked={canViewOrders === null ? false : canViewOrders}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewPublicDocuments">
                    Documenti{" "}
                    <input
                      onChange={() => setCanViewPublicDocuments(!canViewPublicDocuments)}
                      type="checkbox"
                      name="canViewPublicDocuments"
                      id="canViewPublicDocuments"
                      defaultChecked={canViewPublicDocuments === null ? false : canViewPublicDocuments}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewQuotes">
                    Preventivi{" "}
                    <input
                      onChange={() => setCanViewQuotes(!canViewQuotes)}
                      type="checkbox"
                      name="canViewQuotes"
                      id="canViewQuotes"
                      defaultChecked={canViewQuotes === null ? false : canViewQuotes}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="canViewTickets">
                    Tickets{" "}
                    <input
                      onChange={() => setCanViewTickets(!canViewTickets)}
                      type="checkbox"
                      name="canViewTickets"
                      id="canViewTickets"
                      defaultChecked={canViewTickets === null ? false : canViewTickets}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="canViewUsers">
                    Utenti Area{" "}
                    <input
                      onChange={() => setCanViewUsers(!canViewUsers)}
                      type="checkbox"
                      name="canViewUsers"
                      id="canViewUsers"
                      defaultChecked={canViewUsers === null ? false : canViewUsers}
                    />
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <input type="submit" value="Conferma" className="btn btn-secondary mr-1" />
            <input type="button" value="Elimina" className="btn btn-danger" onClick={handleDelete} />
          </form>
        </div>
      ) : (
        <div className="loader-square"></div>
      )}
    </div>
  );
};

export default UsersSingle;
