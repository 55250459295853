import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFile,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import TopBarPage from "../ui-components/top-bar-page";
import { getItems, httpPost } from "../functions/request";
import qs from "qs";
import { useAuth } from "../context/auth.context";
import { useLocation } from "react-router-dom";

const PublicDocument = (props) => {
  const location = useLocation();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [size, setSize] = useState(0);
  const [folders, setFolders] = useState(null);
  const [contents, setContents] = useState(null);
  const [path, setPath] = useState(params.path);
  const { token, user } = useAuth();

  useEffect(() => {
    document.title = "Documenti Pubblici - CIPS";
    if (!user.canViewPublicDocuments) window.location.href = '/';
    callData();
  }, [user]);

  const callData = async () => {
    const data = await getItems("/documents/public/list", token, null, null, {
      path: path,
    });
    console.log(data);
    console.log(data.results);
    console.log(data.results.CommonPrefixes);
    setFolders(data.results.CommonPrefixes ? data.results.CommonPrefixes : []);
    setContents(data.results.Contents ? data.results.Contents : []);
    // setSize(data.results)
  };

  const handleDownload = async (e, download_file) => {
    e.preventDefault();

    const data = await httpPost(
      "/documents/public/download",
      { filename: download_file },
      token
    );

    if (!data.ok) return;

    const bytes = Object.keys(data.results.body).map(k => data.results.body[k])
    var blob = new Blob([new Uint8Array(bytes)], {
      type: data.results.contentType,
    });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = download_file.replace(path, "");

    link.click();
  };

  return (
    <div>
      <TopBarPage page="Documenti Pubblici" />

      <hr />

      <div className="content-page">
        <div className="content-table overflow-auto" style={{ height: "100%" }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nome File</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {path !== "" ? (
                <tr>
                  <td>
                    <a
                      style={{ color: "#000", textDecoration: "none" }}
                      onClick={() => window.history.go(-1)}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: 22, marginRight: 10 }}
                        icon={faFolder}
                      />
                      {" ../"}
                    </a>
                  </td>
                  <td></td>
                </tr>
              ) : null}
              {folders &&
                folders.map((item, index) => {
                  if (item.Prefix.replace(path, "") == "") return null;
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          style={{ color: "#000", textDecoration: "none" }}
                          href={`/documenti-pubblici?path=${path}${item.Prefix.replace(
                            path,
                            ""
                          )}`}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 22, marginRight: 10 }}
                            icon={faFolder}
                          />{" "}
                          {item.Prefix.replace(path, "")}
                        </a>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}

              {contents &&
                contents.map((item, index) => {
                  if (item.Key.replace(path, "") == "") return null;
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          style={{ color: "#000", textDecoration: "none" }}
                          href="#"
                          onClick={(e) => handleDownload(e, item.Key)}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 22, marginRight: 10 }}
                            icon={faFile}
                          />{" "}
                          {item.Key.replace(path, "")}
                        </a>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PublicDocument;
