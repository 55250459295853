import React, { useEffect, useState } from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';

import TopBarPage from '../ui-components/top-bar-page'
import { getItems } from '../functions/request';
import { useExport } from '../context/export-provider';
import icons from "../ui-components/icons";
import { useAuth } from "../context/auth.context";

const Opportunities = () => {
    const { addToExport, toExport, downloadExports } = useExport();
    const [data, setData] = useState(null);
    const [length, setLength] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(12);
    const [order, setOrder] = useState('DESC'); // true DESC false ASC
    const [target, setTarget] = useState('createdtime');
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('');
    const [assignedId, setAssignedId] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [amount, setAmount] = useState(0);
    const { role, token, user } = useAuth();

    useEffect(() => {
        document.title = 'Opportunità - CIPS';
        if (!user.canViewOpportunities) window.location.href = '/';
    }, [user])

    const callData = async () => {
        setData(null);
        let data = await getItems('/opportunities/list', token, pageSize, page, {
            q: query,
            target,
            order,
            filter,
            year,
            assigned: assignedId
        });
        setData(data.results.items);
        setLength(data.results.length);
        setAmount(data.results.sum)
    }

    useEffect(() => {
        callData();
    }, [pageSize, page, target, query, order, filter, assignedId, year])

    const nextPage = async () => {
        const newPage = page + pageSize;
        setPage(newPage);
    }

    const lastPage = async () => {
        const prevPage = page - pageSize;
        setPage(prevPage);
    }

    const searchQuery = async (e, _query) => {
        e.preventDefault();
        setQuery(_query);
        setPage(0);
    }

    const handleFilterData = async (_target) => {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
        setTarget(_target);
    }

    const handleChangeFilter = async (value) => {
        setFilter(value);
    }

    const handleChangeAssigned = async (value) => {
        setAssignedId(value);
    }

    const handleChangeYear = async (value) => {
        setYear(value);
    }

    const handleSetAllChecked = (e) => {
        const checked = e.target.checked;
        let items = document.getElementsByName('itemselect')
        for (let i = 0; i < items.length; i++) {
            items[i].checked = checked;
            console.log(checked);
            addToExport(items[i].value, checked);
        }
    }

    return (
        <div>
            <TopBarPage page="Opportunità" placeholderInput="Cerca per Nome, Numero, Stadio di vendita" searchQuery={searchQuery} setFilterData={handleChangeFilter} setYear={handleChangeYear} role={role} type="Opp" defaultId={null} handleChangeAssigned={handleChangeAssigned} />

            <hr />

            { data != null ?
                <div className="content-page">
                    <div className="d-flex">
                        <p>Visualizzando da {length < 1 ? 0 : page + 1} a {page + pageSize <= length ? page + pageSize : length} di {length}</p>
                        <p className="ml-auto font-weight">Totale: &euro; { amount.toLocaleString('it-IT', { maximumFractionDigits: 2 }) }</p>
                    </div>
                    <div className="content-table overflow-auto" style={{ height: '100%' }}>
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th style={{ width: 55 }}><input style={{ transform: 'scale(1.5)'}} type="checkbox" className="custom-checkbox" onClick={handleSetAllChecked} /></th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('potential_no')}>Nr. Opportunità</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('potentialname')}>Nome Opportunità</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('related_to')}>Collegato a</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('sales_stage')}>Stadio di vendita</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('leadsource')}>Fonte Lead</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('closingdate')}>Data di chiusura attesa</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('amount')}>Ammontare</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('createdtime')}>Data Creazione</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <tr className="table-item-body" id={item.related_to_id} key={index}>
                                                <td style={{ width: '60px' }}>
                                                    <div className="d-flex align-items-center">
                                                        <button type="button" className="open-more badge badge-primary" onClick={(e) => { e.preventDefault(); window.location.href = '/preventivi?account=' + item.id; }}><img src={icons.icoExport} alt="Event Click" /></button>
                                                        <input type="checkbox" value={item.id} className="custom-checkbox" defaultChecked={toExport.includes(item.id)} name="itemselect" onChange={(e) => addToExport(e.target.value, e.target.checked)} />
                                                    </div>
                                                </td>
                                                <td>{item.potential_no}</td>
                                                <td>{item.potentialname}</td>
                                                <td>{item.related_to == '' ? 'n/a' : item.related_to}</td>
                                                <td>{item.sales_stage == '' ? 'n/a' : item.sales_stage}</td>
                                                <td>{item.leadsource == '' ? 'n/a' : item.leadsource}</td>
                                                <td>{item.closingdate == '' ? 'n/a' : item.closingdate}</td>
                                                <td>{item.amount == '' ? '€ 0,00' : `€ ${parseFloat(item.amount).toFixed(2)}`}</td>
                                                <td>{item.createdtime == '' ? 'n/a' : item.createdtime}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                       marginTop: 'auto'
                    }}>
                        <Pagination className="mr-auto">
                            {
                                page >= pageSize ?
                                    <PaginationItem onClick={lastPage}>
                                        <PaginationLink>
                                            Indietro
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                            {
                                length > 12 ?
                                    <PaginationItem onClick={nextPage}>
                                        <PaginationLink>
                                            Avanti
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                        </Pagination>

                        <a href="#" onClick={() => downloadExports('opportunities')} className="btn btn-secondary ml-2">Esporta .XLSX</a>
                    </div>
                </div>
                : <div className="loader-square"></div>}
        </div>
    );
}

export default Opportunities;

