import React from "react";
import './preview-dashboard-item.scss';

const PreviewDashboardItem = ({ icon, link, items, name, thead, tbody }) => {
  console.log(items)
  return (
    <div className="preview-item">
      <div className="preview-top">
        <img src={icon} alt={name} />
        <span>{name}</span>
        <a href={link} className="btn btn-secondary">Lista completa</a>
      </div>
      <hr />
      <div className="preview-table">
        <div className="content-table overflow-auto" style={{ height: '100%' }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>{thead[0]}</th>
                <th>{thead[1]}</th>
                <th>{thead[2]}</th>
              </tr>
            </thead>
            <tbody>
              {
                items != null && items.length > 0 ?
                  items.map((item, index) => {
                    return (
                      <tr className="table-item-body" key={index}>
                        <td style={!isNaN(item[tbody[0]]) ? { textAlign: 'right' } : {}}>{!isNaN(item[tbody[0]]) ? '€ ' + parseFloat(item[tbody[0]]).toFixed(2) : item[tbody[0]]}</td>
                        <td style={!isNaN(item[tbody[1]]) ? { textAlign: 'right' } : {}}>{!isNaN(item[tbody[1]]) ? '€ ' + parseFloat(item[tbody[1]]).toFixed(2) : typeof item[tbody[1]] != 'string' ? 'n/a' : item[tbody[1]].length >= 40 ? item[tbody[1]].substring(0, 40) + '...' : item[tbody[1]]}</td>
                        <td style={!isNaN(item[tbody[1]]) ? { textAlign: 'right' } : {}}>{!isNaN(item[tbody[1]]) ? '€ ' + parseFloat(item[tbody[1]]).toFixed(2) : typeof item[tbody[2]] != 'string' ? 'n/a' : item[tbody[2]].length >= 40 ? item[tbody[2]].substring(0, 40) + '...' : item[tbody[2]]}</td>
                      </tr>
                    );
                  })
                  : null
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PreviewDashboardItem;
