import React, { useState } from 'react'

const ExportContext = React.createContext()

const ExportProvider = (props) => {
    const [toExport, setToExport] = useState([])

    const removeId = async (id) => {
        setToExport(items => items.filter(item => item !== id));
        return ;
    };

    // to do fix remove by index
    const addToExport = async (id, isChecked) => {
        if ( !isChecked ) {
            return removeId(id);
        }
        setToExport(old => [ ...old, id ]);
    };

    const downloadExports = async (module) => {
        let href = `/api/${module}/export?data=${JSON.stringify(toExport)}`;
        window.location.href=href;
    }

    return (
        <ExportContext.Provider
            value={{ addToExport, toExport, downloadExports }}
            {...props}
        />
    )
}

const useExport = () => React.useContext(ExportContext)

export { ExportProvider, useExport }
