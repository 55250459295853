import axios from "axios";

const endpoint = "https://api-resellers.cips.it/api/v1";
//const endpoint = "http://localhost:8000/api/v1";

export const getItemByQuery = async (uri, token, query, limit, offset, params = {}) => {
  const result = await httpGet(uri, { query, limit, offset, ...params }, token);

  if (!result.ok) return {};

  return result.results;
};

export const getItems = async (uri, token, limit, offset, params = {}) => {
  return await httpGet(uri, { limit, offset, ...params }, token);
};

export const request = async (uri, method = "get", params = {}, body = {}) => {
  let result = {};

  try {
    const { data } = await axios({
      url: endpoint + uri,
      method: method,
      params: params,
      data: body,
    });

    result = data;
  } catch (err) {
    result = err.response.data;
  }

  return result;
};

export const httpPost = async (uri, body = {}, token = null) => {
  let result = {};

  try {
    const { data } = await axios({
      url: endpoint + uri,
      method: "POST",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    result = data;
  } catch (err) {
    result = err.response.data;
  }

  return result;
};

export const httpGet = async (uri, params = {}, token = null) => {
  let result = {};

  try {
    const { data } = await axios({
      url: endpoint + uri,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    result = data;
  } catch (err) {
    result = err.response.data;
  }

  return result;
};

export const httpPut = async (uri, body = {}, token = null) => {
  let result = {};

  try {
    const { data } = await axios({
      url: endpoint + uri,
      method: "PUT",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    result = data;
  } catch (err) {
    result = err.response.data;
  }

  return result;
};

export const httpDelete = async (uri, body = {}, token = null) => {
  let result = {};

  try {
    const { data } = await axios({
      url: endpoint + uri,
      method: "DELETE",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    result = data;
  } catch (err) {
    result = err.response.data;
  }

  return result;
};
