import React, { useEffect, useState } from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import qs from 'qs';

import TopBarPage from '../ui-components/top-bar-page'
import { getItemByQuery, getItems, request } from '../functions/request';
import { useExport } from '../context/export-provider';
import { useAuth } from "../context/auth.context";
import { useLocation } from "react-router-dom";

const Invoices = (props) => {
    const { addToExport, toExport, downloadExports } = useExport();
    const location = useLocation();
    const { role, token, user } = useAuth();

    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    
    const [data, setData] = useState(null);
    const [length, setLength] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(12);
    const [order, setOrder] = useState('DESC'); // true DESC false ASC
    const [target, setTarget] = useState('createdtime');
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [assignedId, setAssignedId] = useState(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        document.title = 'Fatture - CIPS';

        if (!user.canViewInvoices) window.location.href = '/';
    }, [user]);

    const callData = async () => {
        setData(null);
        let data = await getItems('/invoices/list', token, pageSize, page, {
            q: query,
            target,
            order,
            filter,
            year,
            assigned: assignedId,
            order_id: params.order
        });
        setData(data.results.items);
        setLength(data.results.length);
        setAmount(data.results.sum)
    }

    useEffect(() => {
        callData();
    }, [pageSize, page, target, query, order, filter, assignedId, year])

    const nextPage = async () => {
        const newPage = page + pageSize;
        setPage(newPage);
    }

    const lastPage = async () => {
        const prevPage = page - pageSize;
        setPage(prevPage);
    }

    const searchQuery = async (e, _query) => {
        e.preventDefault();
        setQuery(_query);
        setPage(0);
    }

    const handleFilterData = async (_target) => {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
        setTarget(_target);
    }

    const handleChangeFilter = async (value) => {
        setFilter(value);
    }

    const handleChangeAssigned = async (value) => {
        setAssignedId(value);
    }

    const handleChangeYear = async (value) => {
        setYear(value);
    }

    const handleSetAllChecked = (e) => {
        const checked = e.target.checked;
        let items = document.getElementsByName('itemselect')
        for (let i = 0; i < items.length; i++) {
            items[i].checked = checked;
            console.log(checked);
            addToExport(items[i].value, checked);
        }
    }

    return (
        <div>
            <TopBarPage setYear={handleChangeYear} page="Fatture" role={role} defaultId={null} handleChangeAssigned={handleChangeAssigned} placeholderInput="Rif, Totale, Data, Agente, Stato" searchQuery={searchQuery} setFilterData={handleChangeFilter} />

            <hr />

            { data != null ?
                <div className="content-page">
                    <div className="d-flex">
                        <p>Visualizzando da {length < 1 ? 0 : page + 1} a {page + pageSize <= length ? page + pageSize : length} di {length}</p>
                        <p className="ml-auto font-weight">Totale: &euro; { amount.toLocaleString('it-IT', { maximumFractionDigits: 2 }) }</p>
                    </div>
                    {
                        params.order ? <a href="javascript:history.go(-1)" className="badge badge-secondary mb-2">Torna Indietro</a> : null
                    }
                    <div className="content-table overflow-auto" style={{ height: '100%' }}>
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th style={{ width: 55 }}><input style={{ transform: 'scale(1.5)'}} type="checkbox" className="custom-checkbox" onClick={handleSetAllChecked} /></th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_1')}>Rif. Fattura</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_6')}>Data Fattura</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_3')}>Azienda</th>
                                    <th style={{ cursor: 'pointer', textAlign: 'right' }} onClick={() => handleFilterData('vcf_21_9')}>Totale</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_11')}>Agente</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_12')}>Scaduto</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_13')}>A Scadere</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('vcf_21_14')}>Stato</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <tr key={index} id={item.id}>
                                                <td>
                                                    <input type="checkbox" value={item.id} className="custom-checkbox" defaultChecked={toExport.includes(item.id)} name="itemselect" onChange={(e) => addToExport(e.target.value, e.target.checked)} />
                                                </td>
                                                <td>{String(item.rif_inv).split('#')[0]}</td>
                                                <td>{item.date}</td>
                                                <td>{item.account}</td>
                                                <td style={{ textAlign: 'right' }}>&euro; {parseFloat(item.tot_inv).toFixed(2)}</td>
                                                <td>{item.agent}</td>
                                                <td>{item.expired}</td>
                                                <td>{item.to_expire}</td>
                                                <td>{item.status}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                       marginTop: 'auto'
                    }}>
                        <Pagination className="mr-auto">
                            {
                                page >= pageSize ?
                                    <PaginationItem onClick={lastPage}>
                                        <PaginationLink>
                                            Indietro
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                            {
                                length > 12 ?
                                    <PaginationItem onClick={nextPage}>
                                        <PaginationLink next>
                                            Avanti
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                        </Pagination>

                        <a href="#" onClick={() => downloadExports('invoices')} className="btn btn-secondary ml-2">Esporta .XLSX</a>
                    </div>
                </div>
                : <div className="loader-square"></div>}
        </div>
    );
}

export default Invoices;
