import React, { useState } from "react";
import { Col, Row, Container, Alert, Input } from "reactstrap";

import Icon from "../../ui-components/icons";
import { useAuth } from '../../context/auth.context';

const Registration = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [agency, setAgency] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [piva, setPiva] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const { signup } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(false);
    setSuccess(false);
    setMessage("");

    const data = {
      firstName: name,
      lastName: surname,
      agency: agency,
      pIva: piva,
      phone: phone,
      email: email,
      username: username,
      password: password
    };

    const [ok, message] = await signup(data);

    if (!ok) {
      setError(true);
      setSuccess(false);
    } else {
      setError(false);
      setSuccess(true);
    }

    setMessage(message);
  };

  return (
    <>
      <Container fluid={true} className="home-page-user-auth">
        <Row>
          <Col sm="6" className="cips-logo">
            <div className="cips-logo-copy">
              <img
                src={Icon.icoCips}
                alt="Logo CIPS"
                style={{
                  width: "100%",
                  maxWidth: 350,
                }}
              />
              <p className="cips-logo-copy-title">Area Riservata</p>
              <p className="cips-logo-copy-text">
                Effettua l'accesso o registrati
              </p>
            </div>
          </Col>
          <Col
            sm="6"
            className="cips-registration-form"
            style={{ background: "#fff" }}
          >
            <form onSubmit={handleSubmit} className="w-100">
              <p className="cips-title">Registrazione</p>
              <p className="cips-question-auth">
                Sei già registrato? <a href="/login">Accedi</a>
              </p>

              <label htmlFor="name">
                Nome <span className="required">*</span>
              </label>
              <Input
                required={true}
                onChange={(e) => setName(e.target.value)}
                type="text"
                name="name"
                id="name"
              />

              <label htmlFor="surname">
                Cognome <span className="required">*</span>
              </label>
              <Input
                required={true}
                onChange={(e) => setSurname(e.target.value)}
                type="text"
                name="surname"
                id="surname"
              />

              <label htmlFor="agency">Azienda</label>
              <Input
                onChange={(e) => setAgency(e.target.value)}
                type="text"
                name="agency"
                id="agency"
              />

              <label htmlFor="piva">
                P. Iva <span className="required">*</span>
              </label>
              <Input
                required={true}
                onChange={(e) => setPiva(e.target.value)}
                type="text"
                name="piva"
                id="piva"
              />

              <label htmlFor="phone">
                Telefono <span className="required">*</span>
              </label>
              <Input
                required={true}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                name="phone"
                id="phone"
              />

              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <Input
                required={true}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
              />

              <label htmlFor="username">
                Username <span className="required">*</span>
              </label>
              <Input
                required={true}
                maxLength="15"
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                name="username"
                id="username"
              />
              <span style={{ fontSize: 12, display: "block", color: "#777" }}>
                Massimo 15 caratteri
              </span>
              <label htmlFor="password">
                Password <span className="required">*</span>
              </label>
              <Input
                required={true}
                maxLength="15"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                id="password"
              />
              <span style={{ fontSize: 12, display: "block", color: "#777" }}>
                Massimo 15 caratteri
              </span>

              <input
                type="submit"
                value="REGISTRATI"
                className="btn btn-block btn-primary"
              />

              {error ? (
                <Alert
                  color="danger"
                  className="font-weight-bold"
                  style={{ marginTop: 10 }}
                >
                  {message}
                </Alert>
              ) : (
                <></>
              )}

              {success ? (
                <Alert
                  color="success"
                  className="font-weight-bold"
                  style={{ marginTop: 10 }}
                >
                  {message}
                </Alert>
              ) : (
                <></>
              )}

              <p className="cips-info">
                Tutti i campi contrassegnati con{" "}
                <span className="required">*</span> devono essere compilati
                obbligatoriamente. <br />
                Registrandoti, dichiari di aver letto e accettato la nostra
                Privacy Policy. La registrazione deve essere convalidata da un
                nostro amministratore, la procedura potrebbe richiedere fino a
                48 ore lavorative. Riceverai una mail al momento della convalida
                con i relativi dati d’accesso.
              </p>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Registration;
