import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';

import { getItems } from '../../functions/request';
import Icon from '../../ui-components/icons';
import PreviewDashboardItem from '../../ui-components/preview-dashboard-item'
import { useAuth } from "../../context/auth.context";
import CarouselPromo from '../../components/carousel-promo';

const DashboardAdmin = () => {
    const [opportunities, setOpportunities] = useState(null)
    const [quotes, setQuotes] = useState(null)
    const [orders, setOrders] = useState(null)
    const [invoices, setInvoices] = useState(null)
    const [assetsRenovated, setAssetsRenovated] = useState(null)
    const [assetsExpire, setAssetsExpire] = useState(null)

    const { token } = useAuth();

    useEffect(() => {
        const getOppotunities = async () => {
            const data = await getItems('/opportunities/list', token, 5, 0, { target: 'createdtime', order: 'DESC' });
            setOpportunities(data.results.items);
        }

        const getQuotes = async () => {
            const data = await getItems('/quotes/list', token, 5, 0, { target: 'createdtime', order: 'DESC' });
            setQuotes(data.results.items);
        }

        const getOrders = async () => {
            const data = await getItems('/orders/list', token, 5, 0, { target: 'createdtime', order: 'DESC' });
            setOrders(data.results.items);
        }

        const getInvoices = async () => {
            const data = await getItems('/invoices/list', token, 5, 0, { target: 'createdtime', order: 'DESC' });
            setInvoices(data.results.items);
        }

        const getAssetsRenovated = async () => {
            const data = await getItems('/assets/list/renovated', token);
            setAssetsRenovated(data.results.items);
        }

        const getAssetsExpire = async () => {
            const data = await getItems('/assets/list/to-expire', token);
            setAssetsExpire(data.results.items);
        }

        getOppotunities();
        getQuotes();
        getOrders();
        getInvoices();
        getAssetsRenovated();
        getAssetsExpire();
    }, []);

    return (
        <div className="dashborad-contents">
            {
                assetsExpire != null && assetsRenovated != null && opportunities != null && quotes != null && orders != null && invoices != null ?
                <>
                        <CarouselPromo />
                        <Row>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoOp}
                                    link="/opportunities"
                                    items={opportunities}
                                    name="Opportunità"
                                    thead={['Nr. Opportunità', 'Nome Opportunità', 'Data chiusura']}
                                    tbody={['potential_no', 'potentialname', 'closingdate']} />
                            </Col>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoPreventici}
                                    link="/preventivi"
                                    items={quotes}
                                    name="Preventivi"
                                    thead={['Nr. Preventivo', 'Soggetto', 'Totale']}
                                    tbody={['quote_no', 'subject', 'hdnGrandTotal']} />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '20px' }}>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoCart}
                                    link="/ordini"
                                    items={orders}
                                    name="Ordini"
                                    thead={['Nr. Ordine', 'Soggetto', 'Totale']}
                                    tbody={['salesorder_no', 'subject', 'hdnGrandTotal']} />
                            </Col>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoFatture}
                                    link="/fatture"
                                    items={invoices}
                                    name="Fatture"
                                    thead={['Rif. Fattura', 'Azianda', 'Totale']}
                                    tbody={['rif_inv', 'account', 'tot_inv']} />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '20px' }}>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoCalendar}
                                    link="/asset-e-scadenze"
                                    items={assetsRenovated}
                                    name="Asset Rinnovati"
                                    thead={['Nr. Installazione', 'Nome Installazione', 'Azienda']}
                                    tbody={['asset_no', 'assetname', 'sorderid']} />
                            </Col>
                            <Col md="6">
                                <PreviewDashboardItem
                                    icon={Icon.icoCalendar}
                                    link="/asset-e-scadenze"
                                    items={assetsExpire}
                                    name="Asset in Scadenza"
                                    thead={['Nr. Installazione', 'Nome Installazione', 'Data Scadenza']}
                                    tbody={['asset_no', 'assetname', 'data_scadenza']} />
                            </Col>
                        </Row>
                    </>
                : <div className="loader-square"></div>
            }
        </div>
    )
};

export default DashboardAdmin;
