import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import TopBarPage from "../ui-components/top-bar-page";
import { getItems } from "../functions/request";
import { useAuth } from "../context/auth.context";
import icons from "../ui-components/icons";

const Users = (props) => {
  const { role, token } = useAuth();

  const [data, setData] = useState(null);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    document.title = "Users - CIPS";
    if (role !== "ADMIN") window.location.href = "/";
  }, [role]);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getItems("/users/list", token, null, null, {});
      const accountsData = await getItems("/accounts/list?limit=25000", token, null, null, {});

      setData(userData.results);
      setAccounts(accountsData.results.items);
    };

    fetchData();
  }, [token]);

  const status = ["IN ATTESA", "ATTIVO", "BLOCCATO"];

  // Funzione per ottenere il nome dell'azienda dato l'ID
  const getAccountNameById = (accountId) => {
    const account = accounts.find((acc) => acc.id === accountId);
    return account ? account.accountname : "";
  };

  return (
    <div>
      <TopBarPage page="Utenti" role={role} showFilterAndOthers={false} />

      <hr />

      {data != null ? (
        <div className="content-page">
          <div className="content-table overflow-auto" style={{ maxHeight: "calc(100% - 81px)" }}>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Cognome</th>
                  <th>Email</th>
                  <th>Username</th>
                  <th>Azienda</th>
                  <th>Ruolo</th>
                  <th>Stato</th>
                  <th>Ultimo accesso</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <a href={`/users/${item.id}`}>
                        <img style={{ width: 32, cursor: "pointer" }} src={icons.icoProfile} alt="Open" />
                      </a>
                    </td>
                    <td>{item.id}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>
                      <a href={`/users/${item.id}`}>{item.username}</a>
                    </td>
                    <td>{getAccountNameById(item.vteAssigned)}</td>
                    <td>{item.role}</td>
                    <td>{status[item.status]}</td>
                    <td>{item.lastLogin}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="loader-square"></div>
      )}
    </div>
  );
};

export default Users;
