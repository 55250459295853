import { AuthProvider } from "./auth.context";
import { ExportProvider } from "./export-provider";

export const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <ExportProvider>{children}</ExportProvider>
    </AuthProvider>
  );
};
