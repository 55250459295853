import React, { useEffect } from "react";

import TopBarPage from "../ui-components/top-bar-page";
import DashboardAdmin from "../components/dashboard-admin";
import DashboardClient from "../components/dashboard-client";
import { useAuth } from "../context/auth.context";

const Dashboard = () => {
  const agentId = localStorage.getItem("agent");
  const { role } = useAuth()

  useEffect(() => {
    document.title = "Dashboard - CIPS";
  }, []);

  return (
    <div className="dashboard">
      <TopBarPage
        showYear={false}
        showAssign={false}
        page="Dashboard"
        searchBtn={false}
      />

      <hr />

      {role == "ADMIN" ? <DashboardAdmin /> : null}
      {role == "CLIENT" || role == "AGENT" || role == "CONTACT" ? <DashboardClient /> : null}
      {agentId !== undefined && agentId !== null && role == "AGENT" ? <DashboardAdmin /> : null}
    </div>
  );
};

export default Dashboard;
