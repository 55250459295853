import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import qs from "qs";

import icons from "../ui-components/icons";
import TopBarPage from "../ui-components/top-bar-page";

import { useExport } from "../context/export-provider";
import { useAuth } from "../context/auth.context";

import { getItems } from "../functions/request";

const Quotes = (props) => {
  const location = useLocation();
  const { role, token, user } = useAuth();
  const { addToExport, toExport, downloadExports } = useExport();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [data, setData] = useState(null);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(12);
  const [order, setOrder] = useState("DESC"); // true DESC false ASC
  const [target, setTarget] = useState("createdtime");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [assignedId, setAssignedId] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    document.title = "Preventivi - CIPS";
    if (!user.canViewQuotes) window.location.href = "/";
  }, [user]);

  const callData = async () => {
    setData(null);
    let data = await getItems("/quotes/list", token, pageSize, page, {
      q: query,
      target,
      order,
      filter,
      year,
      assigned: assignedId,
      account_id: params.account,
    });
    setData(data.results.items);
    setLength(data.results.length);
    setAmount(data.results.sum);
  };

  useEffect(() => {
    callData();
  }, [pageSize, page, target, query, order, filter, assignedId, year]);

  const nextPage = async () => {
    const newPage = page + pageSize;
    setPage(newPage);
  };

  const lastPage = async () => {
    const prevPage = page - pageSize;
    setPage(prevPage);
  };

  const searchQuery = async (e, _query) => {
    e.preventDefault();
    setQuery(_query);
    setPage(0);
  };

  const handleFilterData = async (_target) => {
    setOrder(order === "ASC" ? "DESC" : "ASC");
    setTarget(_target);
  };

  const handleChangeFilter = async (value) => {
    setFilter(value);
  };

  const handleChangeAssigned = async (value) => {
    setAssignedId(value);
  };

  const handleChangeYear = async (value) => {
    setYear(value);
  };

  const handleSetAllChecked = (e) => {
    const checked = e.target.checked;
    let items = document.getElementsByName("itemselect");
    for (let i = 0; i < items.length; i++) {
      items[i].checked = checked;
      addToExport(items[i].value, checked);
    }
  };

  return (
    <div>
      <TopBarPage
        page="Preventivi"
        setYear={handleChangeYear}
        placeholderInput="Cerca per Numero, Soggetto, Stadio, Totale"
        searchQuery={searchQuery}
        setFilterData={handleChangeFilter}
        role={role}
        type="Quo"
        defaultId={null}
        handleChangeAssigned={handleChangeAssigned}
      />

      <hr />

      {data != null ? (
        <div className="content-page">
          <div className="d-flex">
            <p>
              Visualizzando da {length < 1 ? 0 : page + 1} a{" "}
              {page + pageSize <= length ? page + pageSize : length} di {length}
            </p>
            <p className="ml-auto font-weight">Totale: &euro; {amount.toLocaleString('it-IT', { maximumFractionDigits: 2 })}</p>
          </div>
          {params.account ? (
            <a
              href="javascript:history.go(-1)"
              className="badge badge-secondary mb-2"
            >
              Torna Indietro
            </a>
          ) : null}
          <div
            className="content-table overflow-auto"
            style={{ height: "100%" }}
          >
            <table className="table">
              <thead className="thead">
                <tr>
                  <th style={{ width: 55 }}>
                    <input
                      style={{ transform: "scale(1.5)" }}
                      type="checkbox"
                      className="custom-checkbox"
                      onClick={handleSetAllChecked}
                    />
                  </th>
                  <th onClick={() => handleFilterData("quote_no")}>
                    Nr. Preventivo
                  </th>
                  <th onClick={() => handleFilterData("subject")}>Soggetto</th>
                  <th onClick={() => handleFilterData("quotestage")}>
                    Stadio Preventivo
                  </th>
                  <th onClick={() => handleFilterData("potential_id")}>
                    Nome Opportunità
                  </th>
                  <th onClick={() => handleFilterData("account_id")}>
                    Nome Azienda
                  </th>
                  <th onClick={() => handleFilterData("hdnGrandTotal")}>
                    Totale Documento
                  </th>
                  <th onClick={() => handleFilterData("createdtime")}>
                    Data Creazione
                  </th>
                  <th onClick={() => handleFilterData("validtill")}>
                    Data Validità
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} id={item.id}>
                      <td style={{ width: "60px" }}>
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="open-more badge badge-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href = "/ordini?quote=" + item.id;
                            }}
                          >
                            <img src={icons.icoExport} alt="Event Click" />
                          </button>
                          <input
                            type="checkbox"
                            value={item.id}
                            className="custom-checkbox"
                            defaultChecked={toExport.includes(item.id)}
                            name="itemselect"
                            onChange={(e) =>
                              addToExport(e.target.value, e.target.checked)
                            }
                          />
                        </div>
                      </td>
                      <td>{item.quote_no}</td>
                      <td>{item.subject}</td>
                      <td>{item.quotestage == "" ? "n/a" : item.quotestage}</td>
                      <td>
                        {item.potential_id == "" ? "n/a" : item.potential_id}
                      </td>
                      <td>{item.account_id == "" ? "n/a" : item.account_id}</td>
                      <td style={{ textAlign: "right" }}>
                        €{" "}
                        {item.hdnGrandTotal == ""
                          ? "n/a"
                          : parseFloat(item.hdnGrandTotal).toFixed(2)}
                      </td>
                      <td>
                        {item.createdtime == "" ? "n/a" : item.createdtime}
                      </td>
                      <td>{item.validtill == "" ? "n/a" : item.validtill}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <Pagination className="mr-auto">
              {page >= pageSize ? (
                <PaginationItem onClick={lastPage}>
                  <PaginationLink>Indietro</PaginationLink>
                </PaginationItem>
              ) : null}
              {length > 12 ? (
                <PaginationItem onClick={nextPage}>
                  <PaginationLink next>Avanti</PaginationLink>
                </PaginationItem>
              ) : null}
            </Pagination>

            <a
              href="#"
              onClick={() => downloadExports("quotes")}
              className="btn btn-secondary ml-2"
            >
              Esporta .XLSX
            </a>
          </div>
        </div>
      ) : (
        <div className="loader-square"></div>
      )}
    </div>
  );
};

export default Quotes;
