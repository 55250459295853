import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// components
import Sidebar from "./ui-components/sidebar";

// pages
import Login from "./components/auth/Login";
import Registration from "./components/auth/Registration";

import Dashboard from "./pages/Dashboard";
import Status from "./pages/Status";
import Leads from "./pages/Leads";
import Opportunities from "./pages/Opportunities";
import Quotes from "./pages/Quotes";
import Assets from "./pages/Assets";
import Orders from "./pages/Orders";
import Invoices from "./pages/Invoices";
import PublicDocument from "./pages/PublicDocument";
import Tickets from "./pages/Tickets";
import TicketsOther from "./pages/TicketOther";
import Users from "./pages/Users";
import UsersSingle from "./pages/UsersSingle";

import "./sass/app.scss";
import { useAuth } from "./context/auth.context";
import Recovery from "./pages/Recovery";
import PrivateDocument from "./pages/PrivateDocument";
import TicketSingle from "./pages/TicketSingle";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <div className="content-main">
          <Sidebar />
          <div className="main">
            <Routes>
              <Route path={"/"} element={<Dashboard />} exact />
              <Route path={"/status"} element={<Status />} exact />
              <Route path={"/leads"} element={<Leads />} exact />
              <Route
                path={"/opportunities"}
                element={<Opportunities />}
                exact
              />
              <Route path={"/preventivi"} element={<Quotes />} exact />
              <Route path={"/asset-e-scadenze"} element={<Assets />} exact />
              <Route path={"/ordini"} element={<Orders />} exact />
              <Route path={"/fatture"} element={<Invoices />} exact />
              <Route path={"/documenti-pubblici"} element={<PublicDocument />} exact />
              {/* <Route path={"/documenti-privati"} element={<PrivateDocument />} exact /> */}
              <Route path={"/tickets"} element={<Tickets />} exact />
              <Route path={"/tickets/:id"} element={<TicketSingle />} exact />
              <Route path={"/tickets/other"} element={<TicketsOther />} exact />
              <Route path={"/users"} element={<Users />} exact />
              <Route path={"/users/:id"} element={<UsersSingle />} exact />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path={"/login"} element={<Login />} exact />
          <Route path={"/registration"} element={<Registration />} exact />
          <Route path={"/recovery"} element={<Recovery />} exact />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default App;
