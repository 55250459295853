import React, { useEffect, useState } from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';

import TopBarPage from '../ui-components/top-bar-page'
import { getItems } from '../functions/request';
import { useExport } from '../context/export-provider';
import { useAuth } from "../context/auth.context";

const Leads = () => {
    const { addToExport, toExport, downloadExports } = useExport();
    const [data, setData] = useState(null);
    const [length, setLength] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(12);
    const [order, setOrder] = useState('DESC');
    const [target, setTarget] = useState('createdtime');
    const [query, setQuery] = useState('');
    const { token, user } = useAuth();

    useEffect(() => {
        document.title = 'Leads / Contatti - CIPS';
        if (!user.canViewLeads) window.location.href = '/';
    }, [user]);

    const callData = async () => {
        setData(null);
        let data = await getItems('/leads/list', token, pageSize, page, {
            q: query,
            target,
            order
        });
        setData(data.results.items);
        setLength(data.results.length);
    }

    useEffect(() => {
        callData();
    }, [pageSize, page, target, query, order])

    const nextPage = async () => {
        const newPage = page + pageSize;
        setPage(newPage);
    }

    const lastPage = async () => {
        const prevPage = page - pageSize;
        setPage(prevPage);
    }

    const searchQuery = async (e, _query) => {
        e.preventDefault();
        setQuery(_query);
        setPage(0);
    }

    const handleFilterData = async (_target) => {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
        setTarget(_target);
    }

    const handleSetAllChecked = (e) => {
        const checked = e.target.checked;
        let items = document.getElementsByName('itemselect')
        for (let i = 0; i < items.length; i++) {
            items[i].checked = checked;
            console.log(checked);
            addToExport(items[i].value, checked);
        }
    }


    return (
        <div>
            <TopBarPage page="Leads / Contatti" placeholderInput="Cerca per Nome, Cognome, Città, Compagnia, Email, Telefono" searchQuery={searchQuery} />

            <hr />

            { data != null ?
                <div className="content-page">
                    <p>Visualizzando da {length < 1 ? 0 : page + 1} a {page + pageSize <= length ? page + pageSize : length} di {length}</p>
                    <div className="content-table overflow-auto" style={{ height: '100%' }}>
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th style={{ width: 55 }}><input style={{ transform: 'scale(1.5)' }} type="checkbox" className="custom-checkbox" onClick={handleSetAllChecked} /></th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('firstname')}>Nome</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('lastname')}>Cognome</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('city')}>Città</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('company')}>Azienda</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('phone')}>Telefono</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('email')}>Email</th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => handleFilterData('createdtime')}>Data Creazione</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <tr key={index} id={item.id}>
                                                <td>
                                                    <input type="checkbox" value={item.id} className="custom-checkbox" defaultChecked={toExport.includes(item.id)} name="itemselect" onChange={(e) => addToExport(e.target.value, e.target.checked)} />
                                                </td>
                                                <td>{item.firstname}</td>
                                                <td>{item.lastname}</td>
                                                <td>{item.city == '' ? 'n/a' : item.city}</td>
                                                <td>{item.company == '' ? 'n/a' : item.company}</td>
                                                <td>{item.phone == '' ? 'n/a' : item.phone}</td>
                                                <td>{item.email == '' ? 'n/a' : item.email}</td>
                                                <td>{item.createdtime == '' ? 'n/a' : item.createdtime}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                       marginTop: 'auto'
                    }}>
                        <Pagination className="mr-auto">
                            {
                                page >= pageSize ?
                                    <PaginationItem onClick={lastPage}>
                                        <PaginationLink>
                                            Indietro
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                            {
                                length > 12 ?
                                    <PaginationItem onClick={nextPage}>
                                        <PaginationLink next>
                                            Avanti
                                        </PaginationLink>
                                    </PaginationItem>
                                    : null
                            }
                        </Pagination>

                        <a href="#" onClick={() => downloadExports('leads')} className="btn btn-secondary ml-2">Esporta .XLSX</a>
                    </div>
                </div>
                : <div className="loader-square"></div>}
        </div>
    );
}

export default Leads;
