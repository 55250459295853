import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import HelpRequest from "../help-request";
import RenewalRequest from "../renewal-request";
import Configurator from "../configurator";
import Icon from "../../ui-components/icons";
import { getItems } from "../../functions/request";
import PreviewDashboardItem from "../../ui-components/preview-dashboard-item";
import CarouselPromo from '../../components/carousel-promo';

import "./dashboard-client.scss";
import { useAuth } from "../../context/auth.context";

const DashboardClient = () => {
  const [showHelpReq, setShowHelpReq] = useState(false);
  const [showRenewalReq, setShowRenewalReq] = useState(false);
  const [showConf, setShowConf] = useState(false);
  const [opportunities, setOpportunities] = useState(null);
  const [quotes, setQuotes] = useState(null);
  const [orders, setOrders] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [assetsRenovated, setAssetsRenovated] = useState(null);
  const [assetsExpire, setAssetsExpire] = useState(null);
  const [assetsExpired, setAssetsExpired] = useState(null);

  const { role, token } = useAuth();

  useEffect(() => {
    const getAssetsExpired = async () => {
      const data = await getItems("/assets/list/expired", token, 5, 0, {
        target: "createdtime",
        order: "DESC",
      });
      setAssetsExpired(data.results.items);
    };

    const getOppotunities = async () => {
      const data = await getItems("/opportunities/list", token, 5, 0, {
        target: "createdtime",
        order: "DESC",
      });
      setOpportunities(data.results.items);
    };

    const getQuotes = async () => {
      const data = await getItems("/quotes/list", token, 5, 0, {
        target: "createdtime",
        order: "DESC",
      });
      setQuotes(data.results.items);
    };

    const getOrders = async () => {
      const data = await getItems("/orders/list", token, 5, 0, {
        target: "createdtime",
        order: "DESC",
      });
      setOrders(data.results.items);
    };

    const getInvoices = async () => {
      const data = await getItems("/invoices/list", token, 5, 0, {
        target: "createdtime",
        order: "DESC",
      });
      setInvoices(data.results.items);
    };

    const getAssetsRenovated = async () => {
      const data = await getItems("/assets/list/renovated", token);
      setAssetsRenovated(data.results.items);
    };

    const getAssetsExpire = async () => {
      const data = await getItems("/assets/list/to-expire", token);
      setAssetsExpire(data.results.items);
    };

    getOppotunities();
    getQuotes();
    getOrders();
    getInvoices();
    getAssetsRenovated();
    getAssetsExpire();
    getAssetsExpired();
  }, []);

  return (
    <div className="dashborad-contents">
      {/* HERE NEW BANNER */}
      <CarouselPromo />

      <Row>
        <Col md="4" className="mb-2">
          <div
            className="item-btn-dash"
            onClick={() => setShowHelpReq(!showHelpReq)}
          >
            <img src={Icon.icoSupport} alt="Richiedi Supporto" />
            <p>Richiedi Supporto</p>
          </div>
        </Col>
        {role == "CLIENT" ? (
          <Col md="4" className="mb-2">
            <div
              className="item-btn-dash"
              onClick={() => setShowRenewalReq(!showRenewalReq)}
            >
              <img src={Icon.icoRenew} alt="Richiesta di Rinnovo" />
              <p>Richiesta di Rinnovo</p>
            </div>
          </Col>
        ) : null}

        <Col md="4" className="mb-2">
          <div className="item-btn-dash" onClick={() => setShowConf(!showConf)}>
            <img src={Icon.icoConfiguratore} alt="Configuratore" />
            <p>Configuratore</p>
          </div>
        </Col>
      </Row>

      <hr />

      {role == "AGENT" &&
      opportunities != null &&
      quotes != null &&
      orders != null &&
      invoices != null &&
      assetsExpire != null &&
      assetsRenovated != null &&
      assetsExpired != null ? (
        <>
          <Row>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoOp}
                link="/opportunities"
                items={opportunities}
                name="Opportunità"
                thead={["Nr. Opportunità", "Nome Opportunità", "Data chiusura"]}
                tbody={["potential_no", "potentialname", "closingdate"]}
              />
            </Col>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoPreventici}
                link="/preventivi"
                items={quotes}
                name="Preventivi"
                thead={["Nr. Preventivo", "Soggetto", "Totale"]}
                tbody={["quote_no", "subject", "hdnGrandTotal"]}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCart}
                link="/ordini"
                items={orders}
                name="Ordini"
                thead={["Nr. Ordine", "Soggetto", "Totale"]}
                tbody={["salesorder_no", "subject", "hdnGrandTotal"]}
              />
            </Col>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoFatture}
                link="/fatture"
                items={invoices}
                name="Fatture"
                thead={["Rif. Fattura", "Azianda", "Totale"]}
                tbody={["rif_inv", "account", "tot_inv"]}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsRenovated}
                name="Asset Rinnovati"
                thead={["Nr. Installazione", "Nome Installazione", "Azienda"]}
                tbody={["asset_no", "assetname", "sorderid"]}
              />
            </Col>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsExpire}
                name="Asset in Scadenza"
                thead={[
                  "Nr. Installazione",
                  "Nome Installazione",
                  "Data Scadenza",
                ]}
                tbody={["asset_no", "assetname", "data_scadenza"]}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsExpired}
                name="Asset Scaduti"
                thead={[
                  "Nr. Installazione",
                  "Nome Installazione",
                  "Data Scadenza",
                ]}
                tbody={["asset_no", "assetname", "data_scadenza"]}
              />
            </Col>
          </Row>
        </>
      ) : role == "AGENT" ? (
        <p>Caricamento risorse in corso...</p>
      ) : null}

      {role == "CLIENT" &&
      quotes != null &&
      orders != null &&
      invoices != null &&
      assetsExpired != null &&
      assetsExpired != null ? (
        <>
          <Row>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoPreventici}
                link="/preventivi"
                items={quotes}
                name="Preventivi"
                thead={["Nr. Preventivo", "Soggetto", "Totale"]}
                tbody={["quote_no", "subject", "hdnGrandTotal"]}
              />
            </Col>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCart}
                link="/ordini"
                items={orders}
                name="Ordini"
                thead={["Nr. Ordine", "Soggetto", "Totale"]}
                tbody={["salesorder_no", "subject", "hdnGrandTotal"]}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoFatture}
                link="/fatture"
                items={invoices}
                name="Fatture"
                thead={["Rif. Fattura", "Azianda", "Totale"]}
                tbody={["rif_inv", "account", "tot_inv"]}
              />
            </Col>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsRenovated}
                name="Asset Rinnovati"
                thead={["Nr. Installazione", "Nome Installazione", "Azienda"]}
                tbody={["asset_no", "assetname", "sorderid"]}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsExpire}
                name="Asset in Scadenza"
                thead={[
                  "Nr. Installazione",
                  "Nome Installazione",
                  "Data Scadenza",
                ]}
                tbody={["asset_no", "assetname", "data_scadenza"]}
              />
            </Col>

            <Col md="6">
              <PreviewDashboardItem
                icon={Icon.icoCalendar}
                link="/asset-e-scadenze"
                items={assetsExpired}
                name="Asset Scaduti"
                thead={[
                  "Nr. Installazione",
                  "Nome Installazione",
                  "Data Scadenza",
                ]}
                tbody={["asset_no", "assetname", "data_scadenza"]}
              />
            </Col>
          </Row>
        </>
      ) : role == "CLIENT" ? (
        <p>Caricamento risorse in corso...</p>
      ) : null}

      {showHelpReq ? <HelpRequest setShowHelpReq={setShowHelpReq} /> : null}
      {showRenewalReq ? (
        <RenewalRequest setShowRenewalReq={setShowRenewalReq} />
      ) : null}
      {showConf ? <Configurator setShowConf={setShowConf} /> : null}
    </div>
  );
};

export default DashboardClient;
