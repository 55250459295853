import axios from "axios";
import React, { useEffect, useState } from "react";

import "./carousel-promo.scss";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

const CarouselPromo = () => {
  const [posts, setPosts] = useState([]);

  const getMediaInfo = async (link) => {
    const { data } = await axios.get(link, {
      headers: { "Content-Type": "application/json" },
    });

    if (data.length < 1) return null;

    return data[0];
  };

  const getPosts = async () => {
    const { data } = await axios.get(
      "https://dashboard.cips.it/wp-json/wp/v2/posts?_fields=id,title,link,categories,_links&per_page=100&orderby=date&order=desc",
      { headers: { "Content-Type": "application/json" } }
    );

    const items = [];

    for (const item of data) {
      const media = await getMediaInfo(item._links["wp:attachment"][0]["href"]);

      if (media === null) continue;

      items.push({
        title: item.title.rendered,
        link: item.link,
        media: media.guid.rendered,
      });
    }

    setPosts(items);
  };

  useEffect(() => {
    getPosts();
  }, []);

  // State for Active index
  const [activeIndex, setActiveIndex] = React.useState(0);

  // State for Animation
  const [animating, setAnimating] = React.useState(false);

  // Items array length
  // Previous button for Carousel
  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? posts.length : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === posts.length ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  return (
    <Carousel
      previous={previousButton}
      next={nextButton}
      activeIndex={activeIndex}
    >
      {/* <CarouselIndicators
        items={posts}
        activeIndex={activeIndex}
        onClickHandler={(newIndex) => {
          if (animating) return;
          setActiveIndex(newIndex);
        }}
      /> */}
      {posts.map((item, idx) => {
        return (
          <CarouselItem
            key={idx}
            onExited={() => setAnimating(false)}
            onExiting={() => setAnimating(true)}
          >
            <a href={item.link} target="_blank">
              <img src={item.media} alt={item.title} />
            </a>
          </CarouselItem>
        );
      })}
      <CarouselControl
        direction="prev"
        onClickHandler={previousButton}
      />
      <CarouselControl
        direction="next"
        onClickHandler={nextButton}
      />
    </Carousel>
  );
};

export default CarouselPromo;
