import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { useAuth } from '../../context/auth.context';
import { httpPost } from "../../functions/request";
import "./mail-box-help.scss";

const MailBoxHelp = ({ setShowMail }) => {
  const { token } = useAuth();

  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [btnClass, setBtnClass] = useState("btn btn-secondary");
  const [btnText, setBtnText] = useState("Invia messaggio");


  const sendMail = async e => {
    e.preventDefault();

    setBtnClass("btn btn-secondary disabled");
    setBtnText("Invio del messaggio in corso...");

    const data = await httpPost(
      "/users/assistence/request",
      {
        object: object,
        message: message
      },
      token
    );

    if (!data.ok) {
      setBtnClass("btn btn-danger disabled");
      setBtnText(data.message);

      setTimeout(() => {
        setBtnClass("btn btn-secondary");
        setBtnText("Invia messaggio");
      }, 1000);

      return;
    }

    setBtnClass("btn btn-success disabled");
    setBtnText(data.message);

    setTimeout(() => {
      setShowMail(false);
    }, 1000);
  };

  return (
    <div className="mail-box-help-wrapper">
      <div className="mail-box-help">
        <div className="mail-box-top">
          <h2>Assistenza</h2>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setShowMail(false);
            }}
          >
            &times;
          </a>
        </div>

        <hr />

        <form onSubmit={sendMail}>
          <label htmlFor="obj">Oggetto</label>
          <input
            className="form-control"
            type="text"
            id="obj"
            defaultValue={object}
            onChange={e => setObject(e.target.value)}
          />

          <label htmlFor="msg">Messaggio</label>
          <textarea
            className="form-control"
            name="msg"
            id="msg"
            defaultValue={message}
            onChange={e => setMessage(e.target.value)}
          ></textarea>

          <hr />

          <input
            type="submit"
            disabled={btnClass.includes("disabled")}
            className={btnClass}
            value={btnText}
          />
        </form>
      </div>
    </div>
  );
};

export default MailBoxHelp;
